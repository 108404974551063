
import * as THREE from "../../libs/three.js/build/three.module.js";
import {Annotation} from "../Annotation.js";
import {Utils} from "../utils.js";
import {EventDispatcher} from "../EventDispatcher.js";

export class AnnotationTool extends EventDispatcher{
	constructor (viewer) {
		super();

		this.viewer = viewer;
		this.renderer = viewer.renderer;
		this.mouse = new THREE.Vector2(0, 0);
		this.sg = new THREE.SphereGeometry(0.1);
		this.sm = new THREE.MeshNormalMaterial({visible: false});
		this.s = new THREE.Mesh(this.sg, this.sm);
	}

	startInsertion (args = {}) {
		let domElement = this.viewer.renderer.domElement;
		let annotation = new Annotation({
			position: [589748.270, 231444.540, 753.675],
			...args
		});
		this.dispatchEvent({type: 'start_inserting_annotation', annotation: annotation});

		const annotations = this.viewer.scene.annotations;
		annotations.add(annotation);

		let callbacks = {
			cancel: null,
			finish: null,
		};

		let getMouseCoordinate = () =>{
			let target = Utils.getMousePointCloudIntersection(
				this.mouse, 
				this.viewer.scene.getActiveCamera(), 
				this.viewer, 
				this.viewer.scene.pointclouds
			);
			return target;
		}

		let touchInsertionCallback = (e) => {
			if(e.touches.length === 1){
				// convert x y touch to mouse coordinate
				let rect = domElement.getBoundingClientRect();
				let x = e.touches[0].pageX - rect.left;
				let y = e.touches[0].pageY - rect.top;
				this.mouse.set(x, y);

				let mousePostiion = getMouseCoordinate();

				annotation.position.copy(mousePostiion.location);

				domElement.removeEventListener('click', insertionCallback, true);
				domElement.removeEventListener("touchstart", touchInsertionCallback, false);

				droppingMesh();
			}
		}

		let insertionCallback = (e) => {
			if (e.button === THREE.MOUSE.LEFT) {
				callbacks.finish();
			} else if (e.button === THREE.MOUSE.RIGHT) {
				callbacks.cancel();
			}
		};

		callbacks.cancel = e => {
			annotations.remove(annotation);

			domElement.removeEventListener('click', insertionCallback, true);
			domElement.removeEventListener("touchstart", touchInsertionCallback, false);
		};

		callbacks.finish = e => {
			domElement.removeEventListener('click', insertionCallback, true);
			domElement.removeEventListener("touchstart", touchInsertionCallback, false);
		};

		domElement.addEventListener('click', insertionCallback, true);
		domElement.addEventListener('touchstart', touchInsertionCallback, false);

		let drag = (e) => {
			let I = Utils.getMousePointCloudIntersection(
				e.drag.end, 
				e.viewer.scene.getActiveCamera(), 
				e.viewer, 
				e.viewer.scene.pointclouds,
				{pickClipped: true}
			);

			if (I) {
				let sPosition = I.location
				sPosition.z += .1;
				this.s.position.copy(sPosition);
				annotation.position.copy(I.location);
			}
		};

		let drop = (e) => {
			let sPosition = annotation.position;
			sPosition.z += .1;
			this.s.position.copy(sPosition);

			this.dispatchEvent({
				type: "annotation_dropped",
				annotation: annotation
			})

			this.viewer.inputHandler.startDragging(null);
			// to prevent unwanted placement
			this.s.removeEventListener('drag', drag);

			annotation.domElement.css('pointer-events', 'auto');
		};

		let draggingMesh = () => {
			this.viewer.inputHandler.startDragging(this.s);
			// to prevent unwanted placement
			this.s.addEventListener('drag', drag);
		}

		let droppingMesh = () => {
			this.viewer.inputHandler.startDragging(null);
			// to prevent unwanted placement
			this.s.removeEventListener('drag', drag);

			annotation.domElement.css('pointer-events', 'auto');	
		}

		this.s.addEventListener('drag', drag);
		this.s.addEventListener('drop', drop);

		annotation.domElement[0].addEventListener('mousedown', draggingMesh, true);
		annotation.domElement[0].addEventListener('mouseup', droppingMesh, true);

		this.viewer.scene.scene.add(this.s);
		this.viewer.inputHandler.startDragging(this.s);

		return annotation;
	}
	
	update(){
		// let camera = this.viewer.scene.getActiveCamera();
		// let domElement = this.renderer.domElement;
		// let measurements = this.viewer.scene.measurements;

		// const renderAreaSize = this.renderer.getSize(new THREE.Vector2());
		// let clientWidth = renderAreaSize.width;
		// let clientHeight = renderAreaSize.height;

	}

	render(){
		//this.viewer.renderer.render(this.scene, this.viewer.scene.getActiveCamera());
	}
};
