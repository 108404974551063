import * as THREE from "../../libs/three.js/build/three.module.js";
import {EventDispatcher} from "../EventDispatcher.js";
import { DrawSolarPanel } from "./DrawSolarPanel.js";
import { DrawTriangleSurface } from "./DrawTriangleSurface.js";

export class DrawTool extends EventDispatcher{
	constructor (viewer) {
		super();
		this.viewer = viewer;
		this.renderer = viewer.renderer;
		//this.scene = viewer.scene.scene;
		this.scene = new THREE.Scene();
		this.scene.name = 'scne_drawing';
		this.light = new THREE.AmbientLight(0xfefaee, 1.0);
		this.scene.add(this.light);

		this.viewer.addEventListener("update", this.update.bind(this));
		this.viewer.addEventListener("render.pass.perspective_overlay", this.render.bind(this));
		this.viewer.addEventListener("scene_changed", this.onSceneChange.bind(this));

		this.viewer.inputHandler.registerInteractiveScene(this.scene);
		this.onRemove = (e) => {
			e.drawing.clear();
			this.scene.remove(e.drawing);
		};

		this.onAdd = e => {
			this.scene.add(e.drawing);
		};

		this.viewer.scene.addEventListener('drawing_added', this.onAdd);
		this.viewer.scene.addEventListener('drawing_removed', this.onRemove);

		this.surfaces = [];
	}


	onSceneChange(e){
		if(e.oldScene){
			e.oldScene.removeEventListener('drawing_added', this.onAdd);
			e.oldScene.removeEventListener('drawing_removed', this.onRemove);
		}
		e.scene.addEventListener('drawing_added', this.onAdd);
		e.scene.addEventListener('drawing_removed', this.onRemove);
	}

	// Mainly used for reference plane
	drawTriangleSurface(args = {}) {
		if(args.vectorArray && args.vectorArray instanceof Array) {
			let vectorArray = args.vectorArray;
			let scale = args.scale || 70;
			let color = args.color || 0x2196F3; // If undefined use Airobot blue

			let v1 = vectorArray[0];
			let v2 = vectorArray[1];
			let v3 = vectorArray[2];

			
			let drawing = undefined;

			if(this.viewer.scene.drawings && this.viewer.scene.drawings.length > 0) {
				for(let i=0; i<this.viewer.scene.drawings.length; i++) {
					let existingDrawing = this.viewer.scene.drawings[i];
					//console.log("Existing drawing: ", existingDrawing, args);
					if(existingDrawing.name == args.name) {
						drawing = existingDrawing;
					}
				}
			}

			//console.log("Drawing: ", drawing);

			if(!drawing) {
				//console.log("Creating new drawing");
				drawing = new DrawTriangleSurface({name: args.name, scale: scale, color: color});
				drawing.updatePosition([v1, v2, v3]);
				this.viewer.scene.addDrawing(drawing);
			} else {
				//console.log("Upating position of drawing")
				drawing.updatePosition([v1, v2, v3]);
			}
		}
	}


	drawSolarPanel(args = {}) {

		let drawingArgs = {...args, scene: this.scene}
		//console.log("drawing args: ", args);
		let drawing = new DrawSolarPanel(drawingArgs);

		this.dispatchEvent({
			type: 'start_inserting_drawing',
			drawing: drawing
		});

		this.scene.add(drawing);


		this.viewer.inputHandler.startDragging(drawing.box);
		this.viewer.scene.addDrawing(drawing);

		let endDrawing = {
			callback: null
		}

		drawing.cancel_insertion = endDrawing;

		endDrawing.callback = e => {
			this.viewer.inputHandler.drag = null;
			this.viewer.scene.removeDrawing(drawing);
		}

		return drawing;

	}



	update () {
		let camera = this.viewer.scene.getActiveCamera();
		// let drawings = this.viewer.scene.drawings;

		// let clientWidth = this.renderer.getSize().width;
		// let clientHeight = this.renderer.getSize().height;

		this.light.position.copy(camera.position);

		// get camera degree angle...
		// function radiansToDegrees(radians){// Convert Radians to Degrees
		// 	return radians * 180 / Math.PI;
		// }

		// function getCameraAngleVertical(cameraVector){// Get ThreeJS Camera Angle
		// 	return Math.atan2(cameraVector.x, cameraVector.z);
		// }

		// function getCameraAngleHorizontal(cameraVector){// Get ThreeJS Camera Angle
		// 	return Math.atan2(cameraVector.x, cameraVector.y);
		// }

		// let  cameraVerticalAngle = getCameraAngleVertical(camera.getWorldDirection());
		// let cameraHorizontalAngle = getCameraAngleHorizontal(camera.getWorldDirection());
		// let absoluteVericalAngle = Math.abs(cameraVerticalAngle);

		// make size independant of distance
		// for (let drawing of drawings) {
		// 	//drawing.lengthUnit = this.viewer.lengthUnit;
		// 	//drawing.update();	

		// }
	};

	render(){
		this.viewer.renderer.render(this.scene, this.viewer.scene.getActiveCamera());
	}
}