
import * as THREE from "../../libs/three.js/build/three.module.js";
import {Profile} from "./Profile.js";
import {Utils} from "../utils.js";
import { EventDispatcher } from "../EventDispatcher.js";


export class ProfileTool extends EventDispatcher {
	constructor (viewer) {
		super();

		this.viewer = viewer;
		this.renderer = viewer.renderer;
		this.mouse = new THREE.Vector2(0, 0);
		this.addEventListener('start_inserting_profile', e => {
			this.viewer.dispatchEvent({
				type: 'cancel_insertions'
			});
		});

		this.scene = new THREE.Scene();
		this.scene.name = 'scene_profile';
		this.light = new THREE.PointLight(0xffffff, 1.0);
		this.scene.add(this.light);
		this.tabletTouchRemoveFirstPoint = true;
		this.viewer.inputHandler.registerInteractiveScene(this.scene);

		this.onRemove = e => this.scene.remove(e.profile);
		this.onAdd = e => this.scene.add(e.profile);

		for(let profile of viewer.scene.profiles){
			this.onAdd({profile: profile});
		}

		viewer.addEventListener("update", this.update.bind(this));
		viewer.addEventListener("render.pass.perspective_overlay", this.render.bind(this));
		viewer.addEventListener("scene_changed", this.onSceneChange.bind(this));

		viewer.scene.addEventListener('profile_added', this.onAdd);
		viewer.scene.addEventListener('profile_removed', this.onRemove);
	}

	onSceneChange(e){
		if(e.oldScene){
			e.oldScene.removeEventListeners('profile_added', this.onAdd);
			e.oldScene.removeEventListeners('profile_removed', this.onRemove);
		}

		e.scene.addEventListener('profile_added', this.onAdd);
		e.scene.addEventListener('profile_removed', this.onRemove);
	}

	startInsertion (args = {}) {
		let domElement = this.viewer.renderer.domElement;

		let profile = new Profile();
		profile.name = args.name || 'Profile';
		profile.color = args.color;
		this.dispatchEvent({
			type: 'start_inserting_profile',
			profile: profile
		});

		this.scene.add(profile);

		let cancel = {
			callback: null
		};


		let endProfile = e => {
			profile.dispatchEvent({
				'type': 'profile_end',
				'profile': profile,
				// 'index': i
			});
			return cancel.callback()
		}
	
		//touch insertion
		let getMouseCoordinate = () =>{
			let target = Potree.utils.getMousePointCloudIntersection(
				this.mouse, 
				this.viewer.scene.getActiveCamera(), 
				this.viewer, 
				this.viewer.scene.pointclouds
			);
			return target;
		}

		// touch tablet measurment event
		var allowMeasuringToolTimer = null;
		var storeEvent = null;
		var doubleStartTouch = false;
		var allowMeasuringTool = true;
		var markerPanningRemoved = false;
		var panning = false;

		let lastStartTouch = 0;
		let lastEndTouch = 0;


		let touchStartInsertion = (e) => {
			//reset
			allowMeasuringTool = true; 
			// detect if it is a double click or single click to init if it is a panning or drag / drop / rotate
			if(e.touches.length === 1){// single finger or pencil
				let date = new Date();
				let time = date.getTime();
				const time_between_taps = 400; // 200ms

				if (time - lastStartTouch < time_between_taps) doubleStartTouch = true; 

				lastStartTouch = time;
			}

			// because touch end listener does not hold the event, we need to store the last click info like coordinate and such
			storeEvent = e;
		}


		let touchMoveInsertion = (e) => {
			// this timer is for drag and drop rotate the pointcloud

			allowMeasuringToolTimer = setTimeout(() => {
				clearTimeout(allowMeasuringToolTimer);
				allowMeasuringTool = false; 
				if(doubleStartTouch && !markerPanningRemoved){
					profile.removeMarker(profile.points.length - 1);
					markerPanningRemoved = true;
				}
			}, 300);

			// because touch end listener does not hold the event, we need to store the last click info like coordinate and such
			storeEvent = e
		}


		let touchEndInsertionCallback = (e) => {
			e = storeEvent;
			
			clearTimeout(allowMeasuringToolTimer);

			if(allowMeasuringTool) { // if it is not panning drop or dragging mesure then
				if(e.touches.length === 1){
					// on touch remove the first marker with 000 xyz coords
					if(profile.points[0]&&(profile.points[0].x===0&&profile.points[0].y===0&&profile.points[0].z===0)) profile.removeMarker(profile.points[0]);

					let pencil = e.touches[0].touchType === "stylus" ? true : false;

					// convert x y touch to mouse coordinate
					let rect = domElement.getBoundingClientRect();
					let x = e.touches[0].pageX - rect.left;
					let y = e.touches[0].pageY - rect.top;
					this.mouse.set(x, y);

					// get coordiates form 
					let mousePosition = getMouseCoordinate();
					let date = new Date();
					let time = date.getTime();
					let time_between  = pencil? 400 : 500;

					if (time - lastEndTouch < time_between) {// double
						// profile.addMarker(mousePosition.location);
						endProfile();
					}else { // Single OR Double draw
						profile.addMarker(mousePosition.location);
						this.viewer.inputHandler.startDragging(profile.spheres[profile.spheres.length - 1]);
					}

					lastEndTouch = time;
				}
			}
			
			// reset to default.
			storeEvent = null;
			markerPanningRemoved = false;

			if(doubleStartTouch) doubleStartTouch = false;
			if(panning) panning = false;
		}

		// mouse event 
		// pc mouse insertionCallback
		var {doubleDown, lastMarkerRemoved, mouseDown} = false,
			allowMouseMeasuringTool = true, 
			moveTimer, lastClick;

		let mousedownInsertionCallback = (e) => {
			//reset
			e.preventDefault();
			clearTimeout(moveTimer);

			let date = new Date();
			let time = date.getTime();
			const time_between_taps = 400; // 400ms

			allowMouseMeasuringTool = true; 
			mouseDown = true;

			if (time - lastClick < time_between_taps) doubleDown = true;

			lastClick = time;
		}

		let mousemoveInsertionCallback = (e) => {
			e.preventDefault();
			if(mouseDown && allowMouseMeasuringTool){
				moveTimer = setTimeout(() => {
					clearTimeout(moveTimer);

					allowMouseMeasuringTool = false;

					if(doubleDown && !lastMarkerRemoved){
						profile.removeMarker(profile.points.length - 2);
						lastMarkerRemoved = true;
					}
				}, 250);
			}
		}

		let insertionCallback = (e) => {
			e.preventDefault();

			clearTimeout(moveTimer);

			if(allowMouseMeasuringTool){
				profile.addMarker(profile.points[profile.points.length - 1].clone());
				this.viewer.inputHandler.startDragging(profile.spheres[profile.spheres.length - 1]);
			}
			if(mouseDown) mouseDown = false;
			if(lastMarkerRemoved) lastMarkerRemoved = false;
		};


		let dblclickEndCallback = (e) => {
			e.preventDefault();
			clearTimeout(moveTimer);

			if(allowMouseMeasuringTool){
				endProfile();
			}

			if(doubleDown) doubleDown = false;
			if(lastMarkerRemoved) lastMarkerRemoved = false;
			if(allowMouseMeasuringTool) allowMouseMeasuringTool = true; 
		}

		cancel.callback = e => {
			if(!args.tabletDevice) profile.removeMarker(profile.points.length - 1);

			this.viewer.removeEventListener('cancel_insertions', cancel.callback);

			domElement.removeEventListener('click', insertionCallback, true);
			domElement.removeEventListener('dblclick', dblclickEndCallback, true);
			domElement.removeEventListener('mousedown', mousedownInsertionCallback, true);
			domElement.removeEventListener('mousemove', mousemoveInsertionCallback, true);

			domElement.removeEventListener('touchstart', touchStartInsertion);
			domElement.removeEventListener("touchmove", touchMoveInsertion);
			domElement.removeEventListener("touchend", touchEndInsertionCallback);
		};

		this.viewer.addEventListener('cancel_insertions', cancel.callback);
	
		domElement.addEventListener('click', insertionCallback, true);
		domElement.addEventListener('dblclick', dblclickEndCallback, true);
		domElement.addEventListener('mousedown', mousedownInsertionCallback, true);
		domElement.addEventListener('mousemove', mousemoveInsertionCallback, true);

		domElement.addEventListener('touchstart', touchStartInsertion);
		domElement.addEventListener("touchmove", touchMoveInsertion);
		domElement.addEventListener("touchend", touchEndInsertionCallback);

		profile.addMarker(new THREE.Vector3(0, 0, 0));
		this.viewer.inputHandler.startDragging(
			profile.spheres[profile.spheres.length - 1]);

		this.viewer.scene.addProfile(profile);

		return profile;
	}
	
	update(){
		let camera = this.viewer.scene.getActiveCamera();
		let profiles = this.viewer.scene.profiles;
		let renderAreaSize = this.viewer.renderer.getSize(new THREE.Vector2());
		let clientWidth = renderAreaSize.width;
		let clientHeight = renderAreaSize.height;

		this.light.position.copy(camera.position);

		// make size independant of distance
		for(let profile of profiles){
			for(let sphere of profile.spheres){				
				let distance = camera.position.distanceTo(sphere.getWorldPosition(new THREE.Vector3()));
				let pr = Utils.projectedRadius(1, camera, distance, clientWidth, clientHeight);
				let scale = (15 / pr);
				sphere.scale.set(scale, scale, scale);
			}
		}
	}

	render(){
		this.viewer.renderer.render(this.scene, this.viewer.scene.getActiveCamera());
	}

}
