
import * as THREE from "../../libs/three.js/build/three.module.js";
import {TextSprite} from "../TextSprite.js";
import {Utils} from "../utils.js";
import {Line2} from "../../libs/three.js/lines/Line2.js";
import {LineGeometry} from "../../libs/three.js/lines/LineGeometry.js";
import {LineMaterial} from "../../libs/three.js/lines/LineMaterial.js";

function createHeightLine(){
	let lineGeometry = new LineGeometry();

	lineGeometry.setPositions([
		0, 0, 0,
		0, 0, 0,
	]);

	let lineMaterial = new LineMaterial({ 
		color: 0x00ff00, 
		dashSize: 5, 
		gapSize: 2,
		linewidth: 5, 
		resolution:  new THREE.Vector2(1000, 1000),
	});

	lineMaterial.depthTest = false;
	const heightEdge = new Line2(lineGeometry, lineMaterial);
	heightEdge.visible = false;

	//this.add(this.heightEdge);
	
	return heightEdge;
}


function createHeightLabel(){
	const heightLabel = new TextSprite('');

	heightLabel.setTextColor({r: 140, g: 250, b: 140, a: 1.0});
	heightLabel.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
	heightLabel.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
	heightLabel.fontsize = 19;
	heightLabel.material.depthTest = false;
	heightLabel.material.depthWrite = false;
	heightLabel.material.opacity = 1;
	heightLabel.visible = false;

	return heightLabel;
}

function createAreaLabel(){
	const areaLabel = new TextSprite('');

	areaLabel.setTextColor({r: 140, g: 250, b: 140, a: 1.0});
	areaLabel.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
	areaLabel.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
	areaLabel.fontsize = 20;
	areaLabel.material.depthTest = false;
	areaLabel.material.depthWrite = false;
	areaLabel.material.opacity = 1;
	areaLabel.visible = false;
	
	return areaLabel;
}

function createClassificationLabel(classification, color){
	const classificationLabel = new TextSprite(classification, color);

	// classificationLabel.setTextColor(color || {r: 140, g: 250, b: 140, a: 1.0});
	classificationLabel.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
	classificationLabel.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
	classificationLabel.fontsize = 19;
	classificationLabel.material.depthTest = false;
	classificationLabel.material.depthWrite = false;
	classificationLabel.material.opacity = 1;
	classificationLabel.visible = true;
	
	return classificationLabel;
}

function createTitleLabel(title, color){
	const titleLabel = new TextSprite(title, color);

	// titleLabel.setTextColor(color || {r: 140, g: 250, b: 140, a: 1.0});
	titleLabel.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
	titleLabel.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
	titleLabel.fontsize = 19;
	titleLabel.material.depthTest = false;
	titleLabel.material.depthWrite = false;
	titleLabel.material.opacity = 1;
	titleLabel.visible = true;
	
	return titleLabel;
}

function createCircleRadiusLabel(){
	const circleRadiusLabel = new TextSprite("");

	circleRadiusLabel.setTextColor({r: 140, g: 250, b: 140, a: 1.0});
	circleRadiusLabel.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
	circleRadiusLabel.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
	circleRadiusLabel.fontsize = 19;
	circleRadiusLabel.material.depthTest = false;
	circleRadiusLabel.material.depthWrite = false;
	circleRadiusLabel.material.opacity = 1;
	circleRadiusLabel.visible = false;
	
	return circleRadiusLabel;
}

function createCircleRadiusLine(){
	const lineGeometry = new LineGeometry();

	lineGeometry.setPositions([
		0, 0, 0,
		0, 0, 0,
	]);

	const lineMaterial = new LineMaterial({ 
		color: 0xff0000, 
		linewidth: 5, 
		resolution:  new THREE.Vector2(1000, 1000),
		gapSize: 1,
		dashed: true,
	});

	lineMaterial.depthTest = false;
	lineMaterial.depthWrite = false;

	const circleRadiusLine = new Line2(lineGeometry, lineMaterial);
	circleRadiusLine.visible = false;

	return circleRadiusLine;
}

function createCircleLine(){
	const coordinates = [];

	let n = 128;
	for(let i = 0; i <= n; i++){
		let u0 = 2 * Math.PI * (i / n);
		let u1 = 2 * Math.PI * (i + 1) / n;

		let p0 = new THREE.Vector3(
			Math.cos(u0), 
			Math.sin(u0), 
			0
		);

		let p1 = new THREE.Vector3(
			Math.cos(u1), 
			Math.sin(u1), 
			0
		);

		coordinates.push(
			...p0.toArray(),
			...p1.toArray(),
		);
	}

	const geometry = new LineGeometry();
	geometry.setPositions(coordinates);

	const material = new LineMaterial({ 
		color: 0xff0000, 
		dashSize: 5, 
		gapSize: 2,
		linewidth: 5, 
		resolution:  new THREE.Vector2(1000, 1000),
	});

	material.depthTest = false;
	material.depthWrite = false;

	const circleLine = new Line2(geometry, material);
	circleLine.visible = false;
	circleLine.computeLineDistances();

	return circleLine;
}

function createCircleCenter(){
	const sg = new THREE.SphereGeometry(1, 32, 32);
	const sm = new THREE.MeshNormalMaterial();
	
	const circleCenter = new THREE.Mesh(sg, sm);
	circleCenter.visible = false;

	return circleCenter;
}

function createLine(){
	const geometry = new LineGeometry();

	geometry.setPositions([
		0, 0, 0,
		0, 0, 0,
	]);

	const material = new LineMaterial({ 
		color: 0xff0000, 
		linewidth: 5, 
		resolution:  new THREE.Vector2(1000, 1000),
		gapSize: 1,
		dashed: true,
	});

	material.depthTest = false;
	material.depthWrite = false;

	const line = new Line2(geometry, material);

	return line;
}

function createCircle(){

	const coordinates = [];

	let n = 128;
	for(let i = 0; i <= n; i++){
		let u0 = 2 * Math.PI * (i / n);
		let u1 = 2 * Math.PI * (i + 1) / n;

		let p0 = new THREE.Vector3(
			Math.cos(u0), 
			Math.sin(u0), 
			0
		);

		let p1 = new THREE.Vector3(
			Math.cos(u1), 
			Math.sin(u1), 
			0
		);

		coordinates.push(
			...p0.toArray(),
			...p1.toArray(),
		);
	}

	const geometry = new LineGeometry();
	geometry.setPositions(coordinates);

	const material = new LineMaterial({ 
		color: 0xff0000, 
		dashSize: 5, 
		gapSize: 2,
		linewidth: 5, 
		resolution:  new THREE.Vector2(1000, 1000),
	});

	material.depthTest = false;
	material.depthWrite = false;

	const line = new Line2(geometry, material);
	line.computeLineDistances();

	return line;

}

function createAzimuth(){

	const azimuth = {
		label: null,
		center: null,
		target: null,
		north: null,
		centerToNorth: null,
		centerToTarget: null,
		centerToTargetground: null,
		targetgroundToTarget: null,
		circle: null,

		node: null,
	};

	const sg = new THREE.SphereGeometry(1, 32, 32);
	const sm = new THREE.MeshNormalMaterial();

	{
		const label = new TextSprite("");

		label.setTextColor({r: 140, g: 250, b: 140, a: 1.0});
		label.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
		label.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
		label.fontsize = 19;
		label.material.depthTest = false;
		label.material.depthWrite = false;
		label.material.opacity = 1;

		azimuth.label = label;
	}

	azimuth.center = new THREE.Mesh(sg, sm);
	azimuth.target = new THREE.Mesh(sg, sm);
	azimuth.north = new THREE.Mesh(sg, sm);
	azimuth.centerToNorth = createLine();
	azimuth.centerToTarget = createLine();
	azimuth.centerToTargetground = createLine();
	azimuth.targetgroundToTarget = createLine();
	azimuth.circle = createCircle();

	azimuth.node = new THREE.Object3D();
	azimuth.node.add(
		azimuth.centerToNorth,
		azimuth.centerToTarget,
		azimuth.centerToTargetground,
		azimuth.targetgroundToTarget,
		azimuth.circle,
		azimuth.label,
		azimuth.center,
		azimuth.target,
		azimuth.north,
	);

	return azimuth;
}

export class Measure extends THREE.Object3D {
	constructor (color, surfaceCalc, classification, textColor, title, linewidth) {
		super();
		this.constructor.counter = (this.constructor.counter === undefined) ? 0 : this.constructor.counter + 1;

		this.name = 'Measure_' + this.constructor.counter;
		this.points = [];
		this._showDistances = true;
		this._showCoordinates = false;
		this._showArea = false;
		this._closed = true;
		this._showAngles = false;
		this._showCircle = false;
		this._showHeight = false;
		this._showEdges = true;
		this._showAzimuth = false;
		this._showAngleOfInclination = false;
		this.maxMarkers = Number.MAX_SAFE_INTEGER;
		this.color = new THREE.Color(color);
		this._showLabels = false;
		this._showClassifications = false;
		this._measure = null;
		this.hovered = false;
		this._selected = false;
		this._deleted = false;
		this.surfaceCalc = surfaceCalc;
		this.volumeCalc = 0;
		this._classification = classification;
		this._title = title;
		this.refPoint = undefined;
		this.refPlane = undefined;
		this.userAdjustment = 0;
		this.scaleFactor = 0;
		this.createMode = false;
		this.sphereGeometry = new THREE.SphereGeometry(.5, 10, 10);
		this._color = new THREE.Color(color);
		this._textColor = textColor;
		this._hexColor = color;
		this.lengthUnit = {code: 'm'};
		this.linewidth = linewidth
		this.spheres = [];
		this.edges = [];
		this.sphereLabels = [];
		this.edgeLabels = [];
		this.angleLabels = [];
		this.coordinateLabels = [];

		this.heightEdge = createHeightLine();
		this.heightLabel = createHeightLabel();
		this.heightEdgeAngleOfInclination = createHeightLine();
		this.areaLabel = createAreaLabel();
		this.circleRadiusLabel = createCircleRadiusLabel();
		this.circleRadiusLine = createCircleRadiusLine();
		this.circleLine = createCircleLine();
		this.circleCenter = createCircleCenter();

		this.azimuth = createAzimuth();

		this.add(this.heightEdge);
		this.add(this.heightLabel);
		this.add(this.heightEdgeAngleOfInclination);
		this.add(this.areaLabel);
		this.add(this.circleRadiusLabel);
		this.add(this.circleRadiusLine);
		this.add(this.circleLine);
		this.add(this.circleCenter);

		this.add(this.azimuth.node);

		// classification label
		if(this.classification){
			this.classificationLabel = createClassificationLabel(this.classification, textColor);
			this.add(this.classificationLabel);
		}

		// title label
		if(this.title){
			this.titleLabel = createTitleLabel(this.title, textColor);
			this.add(this.titleLabel);
		}

	}

	createSphereMaterial () {
		let sphereMaterial = new THREE.MeshLambertMaterial({
			flatShading: THREE.SmoothShading,
			color: this.color,
			transparent: true,
			opacity: 1,
			depthTest: true,
			depthWrite: true
		});

		return sphereMaterial;
	};

	addMarker (point) {
		if (point.x != null) {
			point = {position: point};
		}else if(point instanceof Array){
			point = {position: new THREE.Vector3(...point)};
		}
		this.points.push(point);

		// sphere
		let sphere = new THREE.Mesh(this.sphereGeometry, this.createSphereMaterial());
		this.children.unshift(sphere) // to avoid sphere coming over the measure lable add all spheres at first of the array
		// this.add(sphere);
		this.spheres.push(sphere);

		{ // edges
			let lineGeometry = new LineGeometry();
			lineGeometry.setPositions( [
					0, 0, 0,
					0, 0, 0,
			]);

			let lineMaterial = new LineMaterial({
				color: this.color, 
				linewidth: this.linewidth || 5, 
				resolution:  new THREE.Vector2(1000, 1000)
			});

			lineMaterial.depthTest = false;
			lineMaterial.depthWrite = false;

			let edge = new Line2(lineGeometry, lineMaterial);
			edge.visible = true;

			this.add(edge);
			this.edges.push(edge);
			
			// Event listeners for the edges
			if(window.user.roles.edit) {

				edge.addEventListener('mouseover', () => {
					//console.log("Type: ", this.name)
					if(this.name === "SolarSurface") {
						document.body.style.cursor = 'pointer';
						this.hovered = true;
					}
					
				});
				edge.addEventListener('mouseleave', () => {
					
					if(!this._selected && this.name === "SolarSurface") {
						this.hovered = false;
					}

					if(this.name === "SolarSurface") {
						document.body.style.cursor = 'auto';
					}
				});
				edge.addEventListener("deselect", () => {
					//console.log('deselecting edge');
					if(this.name === "SolarSurface") {
						let event = {
							type: 'solarSurface_deselect',
							measurement: this,
						};

						this.dispatchEvent(event);
					}
				});
				edge.addEventListener("select", () => {
					//console.log('selecting edge');

					// Select can be a deselect if we are selecting a different edge from the same measurement to deselect the measurement
					if(this.name === "SolarSurface") {
						let event = {}
						if(this._selected) {
							event = {
								type: 'solarSurface_deselect',
								measurement: this,
							};
						} else {
							event = {
								type: 'solarSurface_select',
								measurement: this,
							};
						}

						this.dispatchEvent(event);
						
					}
				});
			}
		}

		{ // edge labels
			let edgeLabel = new TextSprite();
			edgeLabel.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
			edgeLabel.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
			edgeLabel.material.depthTest = false;
			edgeLabel.material.depthWrite = false;
			edgeLabel.visible = false;
			edgeLabel.fontsize = 19;
			this.edgeLabels.push(edgeLabel);
			this.add(edgeLabel);
		}

		{ // angle labels
			let angleLabel = new TextSprite();
			angleLabel.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
			angleLabel.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
			angleLabel.fontsize = 19;
			angleLabel.material.depthTest = false;
			angleLabel.material.opacity = 1;
			angleLabel.visible = false;
			this.angleLabels.push(angleLabel);
			this.add(angleLabel);
		}

		{ // coordinate labels
			let coordinateLabel = new TextSprite();
			coordinateLabel.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
			coordinateLabel.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
			coordinateLabel.fontsize = 19;
			coordinateLabel.material.depthTest = false;
			coordinateLabel.material.depthWrite = false;
			coordinateLabel.material.opacity = 1;
			coordinateLabel.visible = false;
			this.coordinateLabels.push(coordinateLabel);
			this.add(coordinateLabel);
		}

		{ // Event Listeners
			let drag = (e) => {
				let I = Utils.getMousePointCloudIntersection(
					e.drag.end, 
					e.viewer.scene.getActiveCamera(), 
					e.viewer, 
					e.viewer.scene.pointclouds,
					{pickClipped: true});

				if (I) {
					let i = this.spheres.indexOf(e.drag.object);
					if (i !== -1) {
						let point = this.points[i];
						
						// loop through current keys and cleanup ones that will be orphaned
						for (let key of Object.keys(point)) {
							if (!I.point[key]) {
								delete point[key];
							}
						}

						for (let key of Object.keys(I.point).filter(e => e !== 'position')) {
							point[key] = I.point[key];
						}

						this.setPosition(i, I.location);

						let position = I.location;

						// Special case where we want to update the drawing of the plane created with the ref plane points
						if(this.name.includes("RefPlanePoint1")){
							if(e.viewer.scene.drawings && e.viewer.scene.drawings.length > 0) {
								for(let drawing of e.viewer.scene.drawings) {
									if(drawing.name.includes("RefPlane")) {
										drawing.updatePosition([position, null, null]);
									}
								}
							}
							
						}
						if(this.name.includes("RefPlanePoint2")){
							if(e.viewer.scene.drawings && e.viewer.scene.drawings.length > 0) {
								for(let drawing of e.viewer.scene.drawings) {
									if(drawing.name.includes("RefPlane")) {
										drawing.updatePosition([null, position, null]);
									}
								}
							}
							
						}
						if(this.name.includes("RefPlanePoint3")){
							if(e.viewer.scene.drawings && e.viewer.scene.drawings.length > 0) {
								for(let drawing of e.viewer.scene.drawings) {
									if(drawing.name.includes("RefPlane")) {
										drawing.updatePosition([null, null, position]);
									}
								}
							}
							
						}
					}
				}
			};

			let drop = e => {
				let i = this.spheres.indexOf(e.drag.object);
				if (i !== -1) {
					this.dispatchEvent({
						'type': 'marker_dropped',
						'measurement': this,
						'index': i
					});
				}
			};

			let mouseover = (e) => {
				// e.object.material.emissive.setHex(0x888888);
				// e.object.geometry.size = .5
				// e.object.geometry = new THREE.SphereGeometry(.7, 10, 10);
				document.body.style.cursor = 'pointer';
				e.object.material.opacity = .2
			}
			let mouseleave = (e) => {
				// e.object.material.emissive.setHex(0x000000);
				e.object.material.opacity = 1
				// e.object.geometry.size = .3
				// e.object.geometry = new THREE.SphereGeometry(.3, 10, 10);
				document.body.style.cursor = 'auto';
			}

			if(window.user.roles.edit){
				sphere.addEventListener('drag', drag);
				sphere.addEventListener('drop', drop);
				sphere.addEventListener('mouseover', mouseover);
				sphere.addEventListener('mouseleave', mouseleave);

				/*
				sphere.addEventListener("select", e => {

					let event = {
						type: 'solarSurface_select',
						measurement: this,
					};

					this.dispatchEvent(event);
				});

				sphere.addEventListener("deselect", e => {

					let event = {
						type: 'solarSurface_deselect',
						measurement: this,
					};

					this.dispatchEvent(event);
				});
				*/
			}
		}

		let event = {
			type: 'marker_added',
			measurement: this,
			sphere: sphere
		};
		this.dispatchEvent(event);

		this.setMarker(this.points.length - 1, point);
	};

	removeMarker (index) {
		this.points.splice(index, 1);

		this.remove(this.spheres[index]);

		let edgeIndex = (index === 0) ? 0 : (index - 1);
		this.remove(this.edges[edgeIndex]);
		this.edges.splice(edgeIndex, 1);

		this.remove(this.edgeLabels[edgeIndex]);
		this.edgeLabels.splice(edgeIndex, 1);
		this.coordinateLabels.splice(index, 1);

		this.remove(this.angleLabels[index]);
		this.angleLabels.splice(index, 1);

		this.spheres.splice(index, 1);

		this.update();

		this.dispatchEvent({type: 'marker_removed', measurement: this});
	};

	setMarker (index, point) {
		this.points[index] = point;

		let event = {
			type: 'marker_moved',
			measure:	this,
			index:	index,
			position: point.position.clone()
		};
		this.dispatchEvent(event);

		this.update();
	}

	setPosition (index, position) {
		let point = this.points[index];
		point.position.copy(position);

		let event = {
			type: 'marker_moved',
			measure:	this,
			index:	index,
			position: position.clone()
		};
		this.dispatchEvent(event);

		this.update();
	};

	getArea () {
		let area = 0;

		if(this.name==="Area"){
			if(this.points.length>=3) area = this.surfaceCalc(this.points)
			return area
		}else if(this.name==="Volume"){
			return this.volumeCalc;
		}else {
			let j = this.points.length - 1;
			for (let i = 0; i < this.points.length; i++) {
				let p1 = this.points[i].position;
				let p2 = this.points[j].position;
				area += (p2.x + p1.x) * (p1.y - p2.y);
				j = i;
			}
			return Math.abs(area / 2);
		}
	};

	getTotalDistance () {
		if (this.points.length === 0) {
			return 0;
		}

		let distance = 0;

		for (let i = 1; i < this.points.length; i++) {
			let prev = this.points[i - 1].position;
			let curr = this.points[i].position;
			let d = prev.distanceTo(curr);

			distance += d;
		}

		if (this.closed && this.points.length > 1) {
			let first = this.points[0].position;
			let last = this.points[this.points.length - 1].position;
			let d = last.distanceTo(first);

			distance += d;
		}

		return distance;
	}

	getAngleBetweenLines (cornerPoint, point1, point2) {
		let v1 = new THREE.Vector3().subVectors(point1.position, cornerPoint.position);
		let v2 = new THREE.Vector3().subVectors(point2.position, cornerPoint.position);

		// avoid the error printed by threejs if denominator is 0
		const denominator = Math.sqrt( v1.lengthSq() * v2.lengthSq() );
		if(denominator === 0){
			return 0;
		}else{
			return v1.angleTo(v2);
		}
	};

	getAngle (index) {
		if (this.points.length < 3 || index >= this.points.length) {
			return 0;
		}

		let previous = (index === 0) ? this.points[this.points.length - 1] : this.points[index - 1];
		let point = this.points[index];
		let next = this.points[(index + 1) % (this.points.length)];

		return this.getAngleBetweenLines(point, previous, next);
	}

	getHeight () {
		let sorted = this.points.slice().sort((a, b) => a.position.z - b.position.z);
		if(Array.isArray(sorted)&&sorted[0]){
			let lowPoint = sorted[0].position.clone();
			let highPoint = sorted[sorted.length - 1].position.clone();
			let min = lowPoint.z;
			let max = highPoint.z;
			let height = max - min;
	
			return height;
		}
	}

	getAngleOfInclination () {
		let sorted = this.points.slice().sort((a, b) => a.position.z - b.position.z);
		if(Array.isArray(sorted)&&sorted[0]){
			let lowPoint = sorted[0].position.clone();
			let highPoint = sorted[sorted.length - 1].position.clone();
			let min = lowPoint.z;
			let max = highPoint.z;

			let start = new THREE.Vector3(highPoint.x, highPoint.y, min);
			let end = new THREE.Vector3(highPoint.x, highPoint.y, max);

			let angleOfInclnation = this.getAngleBetweenLines({position: lowPoint}, {position: start}, {position: end});
	
			return angleOfInclnation * (180.0 / Math.PI);
		}
	}

	// updateAzimuth(){
	// 	// if(this.points.length !== 2){
	// 	// 	return;
	// 	// }

	// 	// const azimuth = this.azimuth;

	// 	// const [p0, p1] = this.points;

	// 	// const r = p0.position.distanceTo(p1.position);
		
	// }

	update () {
		if (this.points.length === 0) {
			return;
		} else if (this.points.length === 1) {
			let point = this.points[0];
			let position = point.position;
			let sphere = this.spheres[0];
			
			sphere.position.copy(position);
			// update single point color classification
			sphere.material.color = this.color;

			{ // height point coordinate labels
				let coordinateLabel = this.coordinateLabels[0];
				let height = position.z.toFixed(3);


				let suffix = "";
				if(this.lengthUnit != null && this.lengthUnitDisplay != null){
					height = height / this.lengthUnit.unitspermeter * this.lengthUnitDisplay.unitspermeter;  //convert to meters then to the display unit
					suffix = this.lengthUnitDisplay.code;
				}

				let txtHeight = Utils.addCommas(Number(height).toFixed(3));
				let heightMsg = `${txtHeight} ${suffix}`;
				
				if(this.name==="RefPoint"){
					let text = "Height ref";
					coordinateLabel.setText(text);
					coordinateLabel.material.opacity = 1;
				}
				else if(this.name==="RefPlanePoint1"){
					let text = "1";
					coordinateLabel.setText(text);
					coordinateLabel.material.opacity = 1;
				}
				else if(this.name==="RefPlanePoint2"){
					let text = "2";
					coordinateLabel.setText(text);
					coordinateLabel.material.opacity = 1;
				}
				else if(this.name==="RefPlanePoint3"){
					let text = "3";
					coordinateLabel.setText(text);
					coordinateLabel.material.opacity = 1;
				}
				else if(this.name==="RefPointVolume"){
					coordinateLabel.setTextColor({r: 249, g: 105, b: 14, a: 1.0})
					coordinateLabel.setText("Vol. ref.");
					coordinateLabel.material.opacity = 1;
				}else if(this.refPoint && this.name==="Point") {
					let regex = /[+-]?\d+(\.\d+)?/g;
					let refPointMeasure;

					if(this.userAdjustment >= 0) refPointMeasure = parseFloat((this.refPoint - Math.abs(this.userAdjustment)).toFixed(3));
					else if(this.userAdjustment < 0) refPointMeasure = parseFloat((this.refPoint + Math.abs(this.userAdjustment)).toFixed(3));

					//let pointMeasure = heightMsg.match(regex).map(function(v) { return parseFloat(v); });// getting the number from the string
					let pointMeasure = position.z;
					//console.log("PointMeasure: ", pointMeasure)

					//let calc = ( pointMeasure[0] - refPointMeasure ).toFixed(3);
					let calc = ( pointMeasure - refPointMeasure ).toFixed(3);

					//console.log("calculated height: ", calc);

					let suffix = "";
					if(this.lengthUnit != null && this.lengthUnitDisplay != null){
						calc = calc / this.lengthUnit.unitspermeter * this.lengthUnitDisplay.unitspermeter;  //convert to meters then to the display unit
						suffix = this.lengthUnitDisplay.code;
					}

					//console.log("Recalculate: ", Number(calc).toFixed(3));

					let txtHeight = Utils.addCommas(Number(calc).toFixed(3));
					let msg = `${txtHeight} ${suffix}`;

					this.calc = msg;
					coordinateLabel.setText(msg);

				} else if(this.refPlane && this.name==="Point") {

					// p0, p1, p2 --> The points that define the plane
					let p0 = this.refPlane.points.refPlanePoint1;
					let p1 = this.refPlane.points.refPlanePoint2;
					let p2 = this.refPlane.points.refPlanePoint3;

					// U, V --> The vectors that define the plane ; n --> The normalized vector
					let U = {x: null, y: null, z: null};
					let V = {x: null, y: null, z: null};
					let n = {x: null, y: null, z: null};

					U.x=p1.x-p0.x; V.x=p2.x-p0.x; // basis vectors on the plane
					U.y=p1.y-p0.y; V.y=p2.y-p0.y;
					U.z=p1.z-p0.z; V.z=p2.z-p0.z;

					n.x=(U.y*V.z)-(U.z*V.y);      // plane normal
					n.y=(U.z*V.x)-(U.x*V.z);
					n.z=(U.x*V.y)-(U.y*V.x);

					let dist = Math.sqrt( (n.x*n.x) + (n.y*n.y) + (n.z*n.z) ); // normalized
					n.x /= dist;
					n.y /= dist;
					n.z /= dist;
					
					let p = position;
					dist = (p.x-p0.x)*n.x + (p.y-p0.y)*n.y + (p.z-p0.z)*n.z; // your perpendicular distance
					
					let refPointMeasure = 0;

					if(this.userAdjustment >= 0) refPointMeasure = parseFloat((dist - Math.abs(this.userAdjustment)).toFixed(3));
					else if(this.userAdjustment < 0) refPointMeasure = parseFloat((dist + Math.abs(this.userAdjustment)).toFixed(3));


					let suffix = "";
					if(this.lengthUnit != null && this.lengthUnitDisplay != null){
						refPointMeasure = refPointMeasure / this.lengthUnit.unitspermeter * this.lengthUnitDisplay.unitspermeter;  //convert to meters then to the display unit
						suffix = this.lengthUnitDisplay.code;
					}

					let txtHeight = Utils.addCommas(refPointMeasure.toFixed(3));
					let msg = `${txtHeight} ${suffix}`;

					coordinateLabel.setText(msg);

				}else {
					coordinateLabel.setText(heightMsg);
				}

				coordinateLabel.visible = this.showCoordinates;
			}

			{// classification label
				if(this.classification) {
					let p = this.points[0].position;
					let position = new THREE.Vector3(p.x, (p.y  + .5), p.z);
					let dumpPosition = new THREE.Vector3();

					// show / hide classification
					if(this.showClassifications && this.classification !== "NONE")
						this.classificationLabel.position.copy(position);
					else 
						this.classificationLabel.position.copy(dumpPosition);
				}

			}

			{// title label
				if(this.title) {
					let p = this.points[0].position;
					let position = new THREE.Vector3(p.x, (p.y  - .5), p.z);
					let dumpPosition = new THREE.Vector3(); // 

					if(this.showLabels)
						this.titleLabel.position.copy(position);
					else 
						this.titleLabel.position.copy(dumpPosition)
				}
			}

			return;
		}

		let lastIndex = this.points.length - 1;

		let centroid = new THREE.Vector3();
		for (let i = 0; i <= lastIndex; i++) {
			let point = this.points[i];
			centroid.add(point.position);
		}
		centroid.divideScalar(this.points.length);

		for (let i = 0; i <= lastIndex; i++) {
			let index = i;
			let nextIndex = (i + 1 > lastIndex) ? 0 : i + 1;
			let previousIndex = (i === 0) ? lastIndex : i - 1;

			let point = this.points[index];
			let nextPoint = this.points[nextIndex];
			let previousPoint = this.points[previousIndex];

			let sphere = this.spheres[index];

			// spheres
			sphere.position.copy(point.position);
			sphere.material.color = this.color;

			{ // edges
				let edge = this.edges[index];

				edge.material.color = this.color;

				edge.position.copy(point.position);

				edge.geometry.setPositions([
					0, 0, 0,
					...nextPoint.position.clone().sub(point.position).toArray(),
				]);

				edge.geometry.verticesNeedUpdate = true;
				edge.geometry.computeBoundingSphere();
				edge.computeLineDistances();
				edge.visible = index < lastIndex || this.closed;
				
				if(!this.showEdges){
					edge.visible = false;
				}
			}

			{ // edge labels
				let edgeLabel = this.edgeLabels[i];

				let center = new THREE.Vector3().add(point.position);
				center.add(nextPoint.position);
				center = center.multiplyScalar(0.5);
				let distance = point.position.distanceTo(nextPoint.position);
				
				// Check if the measurement does not have a stored scale factor
				if(this.scaleFactor && this.scaleFactor !== 0) {
					if(["ScaleBar"].includes(this.name)) {
						//console.log("Distance: ", this.name, this);
						if(this.actualLength && this.actualLength !== 0) {
							distance = this.actualLength;
						}
					} else {
						//console.log("Scale Factor: ", this);
						distance = distance / this.scaleFactor;
					}
				} else {
					if(this.parent?.children) {
						// We also need to check in case of creation mode if we can take the scale factor into account incase of the presence of a scale bar.
						for(let i=0; i<this.parent.children.length; i++) {
							let child = this.parent.children[i];
							if(child.name === "ScaleBar" && child.scaleFactor !== 0) {
								let scaleFactor = child.scaleFactor;
								//console.log("we have a scale to take into account");
								distance = distance / scaleFactor;
							}
						}
					}
				}

				edgeLabel.position.copy(center);

				let suffix = "";
				if(this.lengthUnit != null && this.lengthUnitDisplay != null){
					distance = distance / this.lengthUnit.unitspermeter * this.lengthUnitDisplay.unitspermeter;  //convert to meters then to the display unit
					suffix = this.lengthUnitDisplay.code;
				}

				let txtLength = Utils.addCommas(distance.toFixed(3));
				edgeLabel.setText(`${txtLength} ${suffix}`);
				edgeLabel.visible = this.showDistances && (index < lastIndex || this.closed) && this.points.length >= 2 && distance > 0;
			}

			{ // angle labels
				let angleLabel = this.angleLabels[i];
				let angle = this.getAngleBetweenLines(point, previousPoint, nextPoint);

				let dir = nextPoint.position.clone().sub(previousPoint.position);
				dir.multiplyScalar(0.5);
				dir = previousPoint.position.clone().add(dir).sub(point.position).normalize();

				let dist = Math.min(point.position.distanceTo(previousPoint.position), point.position.distanceTo(nextPoint.position));
				dist = dist / 9;

				let labelPos = point.position.clone().add(dir.multiplyScalar(dist));
				angleLabel.position.copy(labelPos);

				let msg = Utils.addCommas((angle * (180.0 / Math.PI)).toFixed(1)) + '\u00B0';
				angleLabel.setText(msg);

				angleLabel.visible = this.showAngles && (index < lastIndex || this.closed) && this.points.length >= 3 && angle > 0;
			}
		}

		{ // update height stuff
			let heightEdge = this.heightEdge;
			heightEdge.visible = this.showHeight;
			this.heightLabel.visible = this.showHeight;

			if (this.showHeight) {
				let sorted = this.points.slice().sort((a, b) => a.position.z - b.position.z);
				if(Array.isArray(sorted)&&sorted[0]){
					let lowPoint = sorted[0].position.clone();
					let highPoint = sorted[sorted.length - 1].position.clone();
					let min = lowPoint.z;
					let max = highPoint.z;
					let height = max - min;
	
					let start = new THREE.Vector3(highPoint.x, highPoint.y, min);
					let end = new THREE.Vector3(highPoint.x, highPoint.y, max);
	
					heightEdge.position.copy(lowPoint);
	
					heightEdge.geometry.setPositions([
						0, 0, 0,
						...start.clone().sub(lowPoint).toArray(),
						...start.clone().sub(lowPoint).toArray(),
						...end.clone().sub(lowPoint).toArray(),
					]);
	
					heightEdge.geometry.verticesNeedUpdate = true;
					// heightEdge.geometry.computeLineDistances();
					// heightEdge.geometry.lineDistancesNeedUpdate = true;
					heightEdge.geometry.computeBoundingSphere();
					heightEdge.computeLineDistances();
	
					// heightEdge.material.dashSize = height / 40;
					// heightEdge.material.gapSize = height / 40;
	
					let heightLabelPosition = start.clone().add(end).multiplyScalar(0.5);
					this.heightLabel.position.copy(heightLabelPosition);
					

					let suffix = "";
					if(this.lengthUnit != null && this.lengthUnitDisplay != null){
						height = height / this.lengthUnit.unitspermeter * this.lengthUnitDisplay.unitspermeter;  //convert to meters then to the display unit
						suffix = this.lengthUnitDisplay.code;
					}
	
					let txtHeight = Utils.addCommas(height.toFixed(3));
					let msg = `${txtHeight} ${suffix}`;
					this.heightLabel.setText(msg);
				}
			}
		}

		{ // update angle of inclination
			let heightEdgeAngleOfInclination = this.heightEdgeAngleOfInclination;
			heightEdgeAngleOfInclination.visible = this.showAngleOfInclination;

			if (this.showAngleOfInclination) {
				let sorted = this.points.slice().sort((a, b) => a.position.z - b.position.z);
				if(Array.isArray(sorted)&&sorted[0]){
					let lowPoint = sorted[0].position.clone();
					let highPoint = sorted[sorted.length - 1].position.clone();
					let min = lowPoint.z;
					let max = highPoint.z;
					let height = max - min;
	
					let start = new THREE.Vector3(highPoint.x, highPoint.y, min);
					let end = new THREE.Vector3(highPoint.x, highPoint.y, max);

					let angleOfInclnation = this.getAngleBetweenLines({position: lowPoint}, {position: start}, {position: end});

					let angleLabel = this.angleLabels[0];
					let dir = end.clone().sub(start);
					dir.multiplyScalar(0.5);
					dir = start.clone().add(dir).sub(lowPoint).normalize();

					let dist = Math.min(lowPoint.distanceTo(start), lowPoint.distanceTo(end));
					dist = dist / 9;

					let labelPos = lowPoint.clone().add(dir.multiplyScalar(dist));
					angleLabel.position.copy(labelPos);

					let Anglemsg = Utils.addCommas((angleOfInclnation * (180.0 / Math.PI)).toFixed(1)) + '\u00B0';
					angleLabel.setText(Anglemsg);

					angleLabel.visible = this.showAngleOfInclination;
	
					heightEdgeAngleOfInclination.position.copy(lowPoint);
	
					heightEdgeAngleOfInclination.geometry.setPositions([
						0, 0, 0,
						...start.clone().sub(lowPoint).toArray(),
						...start.clone().sub(lowPoint).toArray(),
						...end.clone().sub(lowPoint).toArray(),
					]);
	
					heightEdgeAngleOfInclination.geometry.verticesNeedUpdate = true;
					//heightEdgeAngleOfInclination.geometry.computeLineDistances();
					//heightEdgeAngleOfInclination.geometry.lineDistancesNeedUpdate = true;
					heightEdgeAngleOfInclination.geometry.computeBoundingSphere();
					heightEdgeAngleOfInclination.computeLineDistances();

					heightEdgeAngleOfInclination.material.dashed = true;
					heightEdgeAngleOfInclination.material.scale = 1;
					heightEdgeAngleOfInclination.material.defines.USE_DASH = "";  // Fucking hack needed for dashing lines in this version of three.js
	
					heightEdgeAngleOfInclination.material.dashSize = height / 40;
					heightEdgeAngleOfInclination.material.gapSize = height / 40;

					//console.log("Height Edge: ", heightEdgeAngleOfInclination);
				}
			}
		}

		{ // update circle stuff
			const circleRadiusLabel = this.circleRadiusLabel;
			const circleRadiusLine = this.circleRadiusLine;
			const circleLine = this.circleLine;
			const circleCenter = this.circleCenter;

			const circleOkay = this.points.length === 3;

			circleRadiusLabel.visible = this.showCircle && circleOkay;
			circleRadiusLine.visible = this.showCircle && circleOkay;
			circleLine.visible = this.showCircle && circleOkay;
			circleCenter.visible = this.showCircle && circleOkay;

			if(this.showCircle && circleOkay){

				const A = this.points[0].position;
				const B = this.points[1].position;
				const C = this.points[2].position;
				const AB = B.clone().sub(A);
				const AC = C.clone().sub(A);
				const N = AC.clone().cross(AB).normalize();

				const center = Utils.computeCircleCenter(A, B, C);
				const radius = center.distanceTo(A);


				const scale = radius / 20;
				circleCenter.position.copy(center);
				circleCenter.scale.set(scale, scale, scale);

				//circleRadiusLine.geometry.vertices[0].set(0, 0, 0);
				//circleRadiusLine.geometry.vertices[1].copy(B.clone().sub(center));

				circleRadiusLine.geometry.setPositions( [
					0, 0, 0,
					...B.clone().sub(center).toArray()
				] );

				circleRadiusLine.geometry.verticesNeedUpdate = true;
				circleRadiusLine.geometry.computeBoundingSphere();
				circleRadiusLine.position.copy(center);
				circleRadiusLine.computeLineDistances();

				const target = center.clone().add(N);
				circleLine.position.copy(center);
				circleLine.scale.set(radius, radius, radius);
				circleLine.lookAt(target);
				
				circleRadiusLabel.visible = true;
				circleRadiusLabel.position.copy(center.clone().add(B).multiplyScalar(0.5));
				circleRadiusLabel.setText(`${radius.toFixed(3)}`);

			}
		}

		{ // update area label
			this.areaLabel.position.copy(centroid);
			this.areaLabel.visible = this.showArea && this.points.length >= 3;
			
			let areaCalc = this.getArea();

			if(this.name==="Volume"){
				if(areaCalc){

					let suffix = "";
					let convertedPositiveVolume;
					let convertedNegativeVolume;
					let positiveVolume = areaCalc.positiveVolume;
					let negativeVolume = areaCalc.negativeVolume;

					// Check if the measurement does not have a stored scale factor
					if(this.scaleFactor && this.scaleFactor !== 0) {
						//console.log("Scale Factor in Area: ", this);
						positiveVolume = positiveVolume / (this.scaleFactor**3);
						negativeVolume = negativeVolume / (this.scaleFactor**3);
					} else {
						if(this.parent?.children) {
							// We also need to check in case of creation mode if we can take the scale factor into account incase of the presence of a scale bar.
							for(let i=0; i<this.parent.children.length; i++) {
								let child = this.parent.children[i];
								if(child.name === "ScaleBar" && child.scaleFactor !== 0) {
									let scaleFactor = child.scaleFactor;
									//console.log("we have a scale to take into account");
									positiveVolume = positiveVolume / (scaleFactor**3);
									negativeVolume = negativeVolume / (scaleFactor**3);
								}
							}
						}
					}

					if(this.lengthUnit != null && this.lengthUnitDisplay != null){
						convertedPositiveVolume = positiveVolume / this.lengthUnit.unitsPerCubicMeters * this.lengthUnitDisplay.unitsPerCubicMeters;  //convert to meters then to the display unit
						convertedNegativeVolume = negativeVolume / this.lengthUnit.unitsPerCubicMeters * this.lengthUnitDisplay.unitsPerCubicMeters;  //convert to meters then to the display unit
						suffix = this.lengthUnitDisplay.code + '\u00B3';
					}

					let txtConvertedPositiveVolume = Utils.addCommas(Number(convertedPositiveVolume).toFixed(3));
					let txtConvertedNegativeVolume = Utils.addCommas(Number(convertedNegativeVolume).toFixed(3));

					let positiveVolumeMsg = `${txtConvertedPositiveVolume} ${suffix}`;
					let negativeVolumeMsg = `${txtConvertedNegativeVolume} ${suffix}`;

					let msg = `+${positiveVolumeMsg} / ${negativeVolumeMsg}`;
					
					this.areaLabel.setText(msg);
				}else {
					let msg = Utils.addCommas(0) + ' ' + this.lengthUnitDisplay.code + '\u00B3';
					this.areaLabel.setText(msg);
				}
			}else if(this.name==="RefPlaneVolume"){
				this.areaLabel.setTextColor({r: 249, g: 105, b: 14, a: 1.0})
				this.areaLabel.setText("Vol. ref.");
			}else {

				let areaCalcNotScaled = areaCalc;
				// Check if the measurement does not have a stored scale factor
				if(this.scaleFactor && this.scaleFactor !== 0) {
					//console.log("Scale Factor in Area: ", this);
					areaCalc = areaCalc / (this.scaleFactor**2);
				} else {
					if(this.parent?.children) {
						// We also need to check in case of creation mode if we can take the scale factor into account incase of the presence of a scale bar.
						for(let i=0; i<this.parent.children.length; i++) {
							let child = this.parent.children[i];
							if(child.name === "ScaleBar" && child.scaleFactor !== 0) {
								let scaleFactor = child.scaleFactor;
								//console.log("we have a scale to take into account");
								areaCalc = areaCalc / (scaleFactor**2);
							}
						}
					}
				}
				
				//console.log("Area: ", areaCalc);
				let suffix = "";
				if(this.lengthUnit != null && this.lengthUnitDisplay != null){
					areaCalc = areaCalc / this.lengthUnit.unitsPerSquaredMeters * this.lengthUnitDisplay.unitsPerSquaredMeters;  //convert to meters then to the display unit
					suffix = this.lengthUnitDisplay.code + '\u00B2';
				}

				let txtAreaCalc = Utils.addCommas(Number(areaCalc).toFixed(3));
				let areaMsg = `${txtAreaCalc} ${suffix}`;

				

				this.areaLabel.setText(areaMsg);

				//this.measure = txtAreaCalc;
				// Don`t save with scale factor being calculated on the measured value
				this.measure = areaCalcNotScaled;
			}
		}

		{ //classification label
			if(this.classification) {
				let point1 = this.points[0].position;
				let point2 = this.points[1].position;

				let middlePosition = {x: (point1.x + point2.x) / 2, y: ((point1.y + point2.y) / 2)  + .7, z: (point1.z + point2.z) / 2};

				let position = new THREE.Vector3(middlePosition.x, middlePosition.y, middlePosition.z);
				let dumpPosition = new THREE.Vector3(); // 
				
				// show / hide classifications
				if(this.showClassifications && this.classification !== "NONE")
					this.classificationLabel.position.copy(position);
				else 
					this.classificationLabel.position.copy(dumpPosition);
			}
		}

		{ //title label
			if(this.title) {
				let point1 = this.points[0].position;
				let point2 = this.points[1].position;

				let middlePosition = {x: (point1.x + point2.x) / 2, y: ((point1.y + point2.y) / 2)  - .7, z: (point1.z + point2.z) / 2};
				
				let position = new THREE.Vector3(middlePosition.x, middlePosition.y, middlePosition.z);
				let dumpPosition = new THREE.Vector3(); // 
				
				// show / hide classifications
				if(this.showLabels)
					this.titleLabel.position.copy(position);
				else 
					this.titleLabel.position.copy(dumpPosition);
			}
		}

		// this.updateAzimuth();
	};

	raycast (raycaster, intersects) {
		for (let i = 0; i < this.points.length; i++) {
			let sphere = this.spheres[i];

			sphere.raycast(raycaster, intersects);
			
			
		}

		// recalculate distances because they are not necessarely correct
		// for scaled objects.
		// see https://github.com/mrdoob/three.js/issues/5827
		// TODO: remove this once the bug has been fixed
		for (let i = 0; i < intersects.length; i++) {
			let I = intersects[i];
			I.distance = raycaster.ray.origin.distanceTo(I.point);
		}
		intersects.sort(function (a, b) { return a.distance - b.distance; });
	};

	get measuringVolume(){
		return this.volumeCalc;
	}

	get getReferencePoint(){
		return this.refPoint;
	}

	get userReferenceAdjustment(){
		return this.userAdjustment;
	}

	set measuringVolume(value) {
		this.volumeCalc = value;
	}

	set adjustRefPoint(value){
		this.refPoint = value;
		this.update();
	}

	set userAdjustmentSetting(value){
		this.userAdjustment = value;
		this.update();
	}

	set setScaleFactor(value) {
		//console.log("Setting Scale factor: ", value);
		this.scaleFactor = value;
		this.update();
	}

	set enableCreateMode(bool){
		this.createMode = bool;
		this.update();
	}

	get color () {
		return this._color;
	}
	set color (value) {
		this._color = new THREE.Color(value);
		this.hexColor = value
		this.update();
	}

	get textColor () {
		return this._color;
	}
	set textColor (value) {
		this._hexColor = value
		this._textColor = value;
		this.update();
	}

	get showClassifications () {
		return this._showClassifications;
	}

	set showClassifications (value) {
		this._showClassifications = value;
		this.update();
	}

	get showLabels () {
		return this._showLabels;
	}

	set showLabels (value) {
		this._showLabels = value;
		this.update();
	}

	get hexColor () {
		return this._hexColor;
	}

	set hexColor (value) {
		return this._hexColor = value
	}

	get measure () {
		return this._measure;
	}
	set measure (value) {
		this._measure = value;
	}

	get showCoordinates () {
		return this._showCoordinates;
	}

	set showCoordinates (value) {
		this._showCoordinates = value;
		this.update();
	}

	get showAngles () {
		return this._showAngles;
	}

	set showAngles (value) {
		this._showAngles = value;
		this.update();
	}

	get showAngleOfInclination () {
		return this._showAngleOfInclination;
	}

	set showAngleOfInclination (value) {
		this._showAngleOfInclination = value;
		this.update();
	}

	get showCircle () {
		return this._showCircle;
	}

	set showCircle (value) {
		this._showCircle = value;
		this.update();
	}

	get showAzimuth(){
		return this._showAzimuth;
	}

	set showAzimuth(value){
		this._showAzimuth = value;
		this.update();
	}

	get showEdges () {
		return this._showEdges;
	}

	set showEdges (value) {
		this._showEdges = value;
		this.update();
	}

	get showHeight () {
		return this._showHeight;
	}

	set showHeight (value) {
		this._showHeight = value;
		this.update();
	}

	get showArea () {
		return this._showArea;
	}

	set showArea (value) {
		this._showArea = value;
		this.update();
	}

	get closed () {
		return this._closed;
	}

	set closed (value) {
		this._closed = value;
		this.update();
	}

	get showDistances () {
		return this._showDistances;
	}

	set showDistances (value) {
		this._showDistances = value;
		this.update();
	}

	get classification () {
		return this._classification;
	}
	set classification (value) {
		if(!(this.classificationLabel instanceof TextSprite)) return;

		this._classification = value;
		// update the potree label 
		this.classificationLabel.setText(value);
		this.update();
	}

	get title () {
		return this._title;
	}
	set title (value) {
		if(!(this.titleLabel instanceof TextSprite)) return;

		this._title = value;
		this.titleLabel.setText(value);
		this.update();
	}


	get selected () {
		return this._selected;
	}
	set selected (value) {
		this._selected = value;
	}

	get deleted () {
		return this._deleted;
	}
	set deleted (value) {
		this._deleted = value;
	}
}
