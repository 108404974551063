import * as THREE from "../../libs/three.js/build/three.module.js";
import {Utils} from "../utils.js";
import {EventDispatcher} from "../EventDispatcher.js";


export class DrawTriangleSurface extends THREE.Object3D {
	constructor(args = {}) {
		super();

		this.color = args.color || 0x2196F3; // If undefined use Airobot blue
		this.name = args.name;
		this.scaleSurfaceScale = args.scale || 70;
		this.v1 = new THREE.Vector3();
		this.v2 = new THREE.Vector3();
		this.v3 = new THREE.Vector3();
		this.triangle = new THREE.Triangle( this.v1, this.v2, this.v3 );
		this.midpoint = this.triangle.midpoint();

		let surfaceFrameGeometry = new THREE.Geometry();
		{
			this.v1 = new THREE.Vector3(0, 0, 0);
			this.v2 = new THREE.Vector3(2, 0, 0);
			this.v3 = new THREE.Vector3(2, 2, 0);

			this.triangle = new THREE.Triangle(this.v1, this.v2, this.v3);
			this.normal = this.triangle.normal();

			surfaceFrameGeometry.vertices.push(this.triangle.a);
			surfaceFrameGeometry.vertices.push(this.triangle.b);
			surfaceFrameGeometry.vertices.push(this.triangle.c);
			
			// Create face top-side
			surfaceFrameGeometry.faces.push(new THREE.Face3(0, 1, 2, this.normal));
			// Create face bottom-side
			surfaceFrameGeometry.faces.push(new THREE.Face3(1, 0, 2, this.normal));

		}

		this.material = new THREE.MeshBasicMaterial({
			color: this.color,
			transparent: true,
			//side: THREE.DoubleSide,
			opacity: 0.1,
			depthTest: false,
			depthWrite: false,
		});

		this.surface = new THREE.Mesh(surfaceFrameGeometry, this.material);
		this.surface.geometry.computeBoundingBox();
		this.surface.geometry.computeVertexNormals();
		this.boundingBox = this.surface.geometry.boundingBox;
		this.add(this.surface);

		this.surface.name = this.name;
			
	}

	updatePosition (vector_array) {
		if(vector_array instanceof Array && vector_array.length === 3) {
			let v1 = new THREE.Vector3();
			v1.copy(this.v1);
			let v2 = new THREE.Vector3();
			v2.copy(this.v2);
			let v3 = new THREE.Vector3();
			v3.copy(this.v3);

			
			if(vector_array[0] !== null) {
				v1.copy(vector_array[0]);
				this.v1.copy(vector_array[0]);
			}
			if(vector_array[1] !== null) {
				v2.copy(vector_array[1]);
				this.v2.copy(v2);
			}
			if(vector_array[2] !== null) {
				v3.copy(vector_array[2]);
				this.v3.copy(v3);
			}

			this.triangle = new THREE.Triangle( v1, v2, v3 );
			this.midpoint = this.triangle.midpoint();

			this.position.copy(this.midpoint);

			this.updateVertices([v1, v2, v3]);
			
			// This scale is more static and does not take zooming into account like the one in the DrawTool.update function.
			this.scale.set(this.scaleSurfaceScale, this.scaleSurfaceScale, this.scaleSurfaceScale);
		}
	}

	updateVertices(vector_array) {
		if(vector_array instanceof Array && vector_array.length === 3) {

			this.v1 = vector_array[0];
			this.v2 = vector_array[1];
			this.v3 = vector_array[2];
			
			let localV1 = new THREE.Vector3((this.v1.x - this.midpoint.x), (this.v1.y - this.midpoint.y), (this.v1.z - this.midpoint.z)); 
			let localV2 = new THREE.Vector3((this.v2.x - this.midpoint.x), (this.v2.y - this.midpoint.y), (this.v2.z - this.midpoint.z)); 
			let localV3 = new THREE.Vector3((this.v3.x - this.midpoint.x), (this.v3.y - this.midpoint.y), (this.v3.z - this.midpoint.z)); 

			this.surface.geometry.vertices[0].copy(localV1);
			this.surface.geometry.vertices[1].copy(localV2);
			this.surface.geometry.vertices[2].copy(localV3);
			
			this.surface.geometry.computeVertexNormals();

			this.surface.geometry.verticesNeedUpdate = true;
		}
	};

	update () {
		this.boundingBox = this.surface.geometry.boundingBox;
		this.boundingSphere = this.boundingBox.getBoundingSphere();
	};

	raycast (raycaster, intersects) {
		let is = [];
		this.surface.raycast(raycaster, is);

		if (is.length > 0) {
			let I = is[0];
			intersects.push({
				distance: I.distance,
				object: this,
				point: I.point.clone()
			});
		}
	};


}
