/**
 * @author mschuetz / http://mschuetz.at
 *
 * adapted from THREE.OrbitControls by
 *
 * @author qiao / https://github.com/qiao
 * @author mrdoob / http://mrdoob.com
 * @author alteredq / http://alteredqualia.com/
 * @author WestLangley / http://github.com/WestLangley
 * @author erich666 / http://erichaines.com
 *
 *
 *
 */

import * as THREE from "../../libs/three.js/build/three.module.js";
import {MOUSE} from "../defines.js";
import {Utils} from "../utils.js";
import {EventDispatcher} from "../EventDispatcher.js";

 
export class OrbitControls extends EventDispatcher{
	
	constructor(viewer){
		super();
		
		this.viewer = viewer;
		this.renderer = viewer.renderer;

		this.scene = null;
		this.sceneControls = new THREE.Scene();

		this.rotationSpeed = 5;

		this.fadeFactor = 20;
		this.yawDelta = 0;
		this.pitchDelta = 0;
		this.panDelta = new THREE.Vector2(0, 0);
		this.radiusDelta = 0;

		this.doubleClockZoomEnabled = false;

		this.zoomSpeed = .2; // zoom speed yo!
		this.tweens = [];
		this.autoMaterialSize = false;
		this.pointSize = 1;
		this.lowestLevel = 3.0;
		this.lowLevel = 7.0;
		this.middleLevel = 20;
		this.highLevel = 40;
		this.hadScroll = false; // this is needed to limit the scroll event if it happens to much. Some device has very sensitive mouses with high scrolling speeds.
		this.dragDropTimer = false;

		this.dynamicUpdatePointSize =  (resolvedRadius) => {
			let poincloud = this.scene.pointclouds[0];
	
			//console.log("Resolve Radius: ", resolvedRadius);
			if(this.autoMaterialSize) {
				this.pointSize = ((0.00278530787525053*Math.pow(resolvedRadius,2)) - (0.312907751113027*resolvedRadius)+8.70270868265419).toFixed(2);
				// Limit the size when zooming out. Typically with polynomial aprouchs the values becomes greater when value reaches a certain point
				if(resolvedRadius > 70){
					this.pointSize = 1;
				}
	
				// Also prevent for pointSize to go below 1. Below 1 is not always supported
				if(this.pointSize < 1) {
					this.pointSize = 1;
				}
	
			
				//console.log("Material Size: ", pointSize);
				poincloud.material.size = this.pointSize;
			}
		}

		this.zoomIn = (value) => {
			let resolvedRadius = (this.scene.view.radius + this.radiusDelta) * this.zoomSpeed;
		
			this.dynamicUpdatePointSize(resolvedRadius);
	
			if(resolvedRadius < this.lowestLevel) {
				resolvedRadius = 0.05 * value;
			} else if(resolvedRadius >= this.lowestLevel && resolvedRadius < this.lowLevel) {
				resolvedRadius = 0.1 * value;
			} else if(resolvedRadius >= this.lowLevel && resolvedRadius < this.middleLevel) {
				resolvedRadius = 0.3 * value;
			} else if( resolvedRadius >= this.middleLevel && resolvedRadius < this.highLevel) {
				resolvedRadius = 0.5 * value;
			} else if( resolvedRadius >= this.highLevel) {
				resolvedRadius = 1.0 * value;
			}
	
			//console.log("resolveRadius zoom in: ", resolvedRadius)
			this.radiusDelta += -resolvedRadius;
			return this.stopTweens();
		}
	
		this.zoomOut = (value) => {
			// zoomingout
			let resolvedRadius = (this.scene.view.radius + this.radiusDelta) * this.zoomSpeed;
	
			this.dynamicUpdatePointSize(resolvedRadius);
	
			if(resolvedRadius < this.lowestLevel) {
				resolvedRadius = 0.5 * value;
			} else if(resolvedRadius >= this.lowestLevel && resolvedRadius < this.lowLevel) {
				resolvedRadius = 0.5 * value;
			} else if( resolvedRadius >= this.lowLevel && resolvedRadius < this.middleLevel) {
				resolvedRadius = 0.2 * value;
			} else if( resolvedRadius >= this.middleLevel) {
				resolvedRadius = 0.1 * value;
			}
	
			//console.log("resolveRadius zoom out: ", resolvedRadius)
			this.radiusDelta += resolvedRadius;
			return this.stopTweens();
		};

		let drag = (e) => {
			if (e.drag.object !== null) {
				return;
			}

			if (e.drag.startHandled === undefined) {
				e.drag.startHandled = true;

				this.dispatchEvent({type: 'start'});
			}

			let ndrag = {
				x: e.drag.lastDrag.x / this.renderer.domElement.clientWidth,
				y: e.drag.lastDrag.y / this.renderer.domElement.clientHeight
			};

			if (e.drag.mouse === MOUSE.LEFT) {
				this.yawDelta += ndrag.x * this.rotationSpeed;
				this.pitchDelta += ndrag.y * this.rotationSpeed;

				this.stopTweens();
			} else if (e.drag.mouse === MOUSE.RIGHT) {
				this.panDelta.x += ndrag.x;
				this.panDelta.y += ndrag.y;

				this.stopTweens();
			}
		};

		let drop = e => {
			this.dispatchEvent({type: 'end'});
		};

		let scroll = (e) => {
			// Limit the scrolling event. This for having a more generic feeling on diferent devices
			if(!this.hadScroll) {

				this.hadScroll = true;
				let resolvedRadius = (this.scene.view.radius + this.radiusDelta) * this.zoomSpeed;
				
				this.dynamicUpdatePointSize(resolvedRadius);

				//console.log("Resolve Radius: ", resolvedRadius);
				// Limit the zooming
				if(e.delta > 0) { // Zooming in 
					if(resolvedRadius < this.lowestLevel) {
						resolvedRadius = 0.2;
					} else if(resolvedRadius >= this.lowestLevel && resolvedRadius < this.lowLevel) {
						resolvedRadius = 1;
					} else if(resolvedRadius >= this.lowLevel && resolvedRadius < this.middleLevel) {
						resolvedRadius = 2
					} else if( resolvedRadius >= this.middleLevel && resolvedRadius < this.highLevel) {
						resolvedRadius = 3;
					} else if( resolvedRadius >= this.highLevel) {
						resolvedRadius = 4;
					}
				} else { // Zooming out
					if(resolvedRadius < this.lowestLevel) {
						resolvedRadius = 1;
					} else if(resolvedRadius >= this.lowestLevel && resolvedRadius < this.lowLevel) {
						resolvedRadius = 1
					} else if( resolvedRadius >= this.lowLevel && resolvedRadius < this.middleLevel) {
						resolvedRadius = 2;
					} else if( resolvedRadius >= this.middleLevel) {
						resolvedRadius = 3;
					}
				}

				this.radiusDelta += -e.delta * resolvedRadius;
				//this.radiusDelta += -e.delta * resolvedRadius * 0.05;
				setTimeout(() => {
					this.hadScroll = false;
				}, 50);
				this.stopTweens();

			}


			// let resolvedRadius = this.scene.view.radius + this.radiusDelta;

			// this.radiusDelta += -e.delta * resolvedRadius * 0.1;

			// this.stopTweens();
		};

		let dblclick = (e) => {
			if(this.doubleClockZoomEnabled){
				this.zoomToLocation(e.mouse);
			}
		};

		let previousTouch = null;
		
		let lastTouch = 0;
		var toucheStylusList = {length: 0, type: null};
		var doubleTouch = false;
		var controlDragDropTimer = null;
		var timerAdded = false;
		var allowPanning = false;
		var panningTimer


		let touchStart = e => {
			if(panningTimer) clearTimeout(panningTimer);
			
			this.removeEventListener('drag', drag);
			this.removeEventListener('drop', drop);

			clearTimeout(controlDragDropTimer);
			
			if(e.touches.length===2){
				//activate drag - drop and zoom
				this.addEventListener('drag', drag);
				this.addEventListener('drop', drop);
			}

			if(e.touches.length === 1){// check if double click
				let date = new Date();
				let time = date.getTime();
				const time_between_taps = 500; // 200ms
				if (time - lastTouch < time_between_taps) {
					// double click 
					doubleTouch = true;
				}
				
				lastTouch = time;
			}
			//
			previousTouch = e;
		};

		let touchEnd = e => {
			if(panningTimer) clearTimeout(panningTimer);
			
			clearTimeout(controlDragDropTimer);

			this.addEventListener('drag', drag);
			this.addEventListener('drop', drop);
			
			timerAdded= false;

			toucheStylusList.length = 0;
			toucheStylusList.type = null;
			doubleTouch = false;
			allowPanning = false;
			//
			previousTouch = e;
		};

		let touchMove = e => {
			if(e.touches.length===1 && previousTouch.touches.length === 1){

				if(doubleTouch){
					this.removeEventListener('drag', drag);
					this.removeEventListener('drop', drop);
					timerAdded= false;

					toucheStylusList.length = 1; // one touch

					if(allowPanning===false){
						controlDragDropTimer = setTimeout(() => {
							clearTimeout(controlDragDropTimer)
							allowPanning = true;
						}, 250);
					}

					if(allowPanning){
						// PAN
						//record current touch
						toucheStylusList.type = "stylus"; // never mind

						let prev = previousTouch;
						let curr = e;
						let prevMeanX = (prev.touches[0].pageX) / 1;
						let prevMeanY = (prev.touches[0].pageY) / 1;

						let currMeanX = (curr.touches[0].pageX) / 1;
						let currMeanY = (curr.touches[0].pageY) / 1;

						let delta = {
							x: (currMeanX - prevMeanX) / this.renderer.domElement.clientWidth,
							y: (currMeanY - prevMeanY) / this.renderer.domElement.clientHeight
						};
						this.panDelta.x += delta.x;
						this.panDelta.y += delta.y;
						this.stopTweens();
					}

				}else {
					if(this.dragDropTimer){
						if(timerAdded===false){
							timerAdded= true;
							// clearTimeout(controlDragDropTimer);
							controlDragDropTimer = setTimeout(() => {
								clearTimeout(controlDragDropTimer)
								this.addEventListener('drag', drag);
								this.addEventListener('drop', drop);
							}, 200);
						}
					}else {
						if(timerAdded===false){
							timerAdded= true;

							this.addEventListener('drag', drag);
							this.addEventListener('drop', drop);
						}
					}
				}
			}else if (e.touches.length === 2 && previousTouch.touches.length === 2){ // for Two fingers ZOOM
				let prev = previousTouch;
				let curr = e;

				let prevDX = prev.touches[0].pageX - prev.touches[1].pageX;
				let prevDY = prev.touches[0].pageY - prev.touches[1].pageY;
				let prevDist = Math.sqrt(prevDX * prevDX + prevDY * prevDY);

				let currDX = curr.touches[0].pageX - curr.touches[1].pageX;
				let currDY = curr.touches[0].pageY - curr.touches[1].pageY;
				let currDist = Math.sqrt(currDX * currDX + currDY * currDY);

				let delta = currDist / prevDist;
				

				let resolvedRadius = this.scene.view.radius + this.radiusDelta;

				this.dynamicUpdatePointSize(resolvedRadius);

				// Limit the zooming
				if(currDist > prevDist){// ZOOM out
					
					if(resolvedRadius < this.lowestLevel) {
						resolvedRadius = 0.03;
					} else if(resolvedRadius >= this.lowestLevel && resolvedRadius < this.lowLevel) {
						resolvedRadius = 0.1;
					} else if(resolvedRadius >= this.lowLevel && resolvedRadius < this.middleLevel) {
						resolvedRadius = 0.5
					} else if( resolvedRadius >= this.middleLevel && resolvedRadius < this.highLevel) {
						resolvedRadius = 2;
					} else if( resolvedRadius >= this.highLevel) {
						resolvedRadius = 3;
					}
					delta = -delta;
				} else {
					
					if(resolvedRadius < this.lowestLevel) {
						resolvedRadius = 0.1;
					} else if(resolvedRadius >= this.lowestLevel && resolvedRadius < this.lowLevel) {
						resolvedRadius = 0.5
					} else if( resolvedRadius >= this.lowLevel && resolvedRadius < this.middleLevel) {
						resolvedRadius = 1;
					} else if( resolvedRadius >= this.middleLevel) {
						resolvedRadius = 2;
					}
					
					delta = delta;
				}

				//let newRadius = resolvedRadius / (delta);
				//this.radiusDelta = newRadius - resolvedRadius;

				this.radiusDelta += delta * resolvedRadius;
				this.stopTweens();
			}else if(e.touches.length === 3 && previousTouch.touches.length === 3){
				let prev = previousTouch;
				let curr = e;

				let prevMeanX = (prev.touches[0].pageX + prev.touches[1].pageX + prev.touches[2].pageX) / 3;
				let prevMeanY = (prev.touches[0].pageY + prev.touches[1].pageY + prev.touches[2].pageY) / 3;

				let currMeanX = (curr.touches[0].pageX + curr.touches[1].pageX + curr.touches[2].pageX) / 3;
				let currMeanY = (curr.touches[0].pageY + curr.touches[1].pageY + curr.touches[2].pageY) / 3;

				let delta = {
					x: (currMeanX - prevMeanX) / this.renderer.domElement.clientWidth,
					y: (currMeanY - prevMeanY) / this.renderer.domElement.clientHeight
				};

				this.panDelta.x += delta.x;
				this.panDelta.y += delta.y;

				this.stopTweens();
			}
			
			// if (e.touches.length === 2 && previousTouch.touches.length === 2){
			// 	let prev = previousTouch;
			// 	let curr = e;

			// 	let prevDX = prev.touches[0].pageX - prev.touches[1].pageX;
			// 	let prevDY = prev.touches[0].pageY - prev.touches[1].pageY;
			// 	let prevDist = Math.sqrt(prevDX * prevDX + prevDY * prevDY);

			// 	let currDX = curr.touches[0].pageX - curr.touches[1].pageX;
			// 	let currDY = curr.touches[0].pageY - curr.touches[1].pageY;
			// 	let currDist = Math.sqrt(currDX * currDX + currDY * currDY);

			// 	let delta = currDist / prevDist;
			// 	let resolvedRadius = this.scene.view.radius + this.radiusDelta;
			// 	let newRadius = resolvedRadius / delta;
			// 	this.radiusDelta = newRadius - resolvedRadius;

			// 	this.stopTweens();
			// }else if(e.touches.length === 3 && previousTouch.touches.length === 3){
			// 	let prev = previousTouch;
			// 	let curr = e;

			// 	let prevMeanX = (prev.touches[0].pageX + prev.touches[1].pageX + prev.touches[2].pageX) / 3;
			// 	let prevMeanY = (prev.touches[0].pageY + prev.touches[1].pageY + prev.touches[2].pageY) / 3;

			// 	let currMeanX = (curr.touches[0].pageX + curr.touches[1].pageX + curr.touches[2].pageX) / 3;
			// 	let currMeanY = (curr.touches[0].pageY + curr.touches[1].pageY + curr.touches[2].pageY) / 3;

			// 	let delta = {
			// 		x: (currMeanX - prevMeanX) / this.renderer.domElement.clientWidth,
			// 		y: (currMeanY - prevMeanY) / this.renderer.domElement.clientHeight
			// 	};

			// 	this.panDelta.x += delta.x;
			// 	this.panDelta.y += delta.y;

			// 	this.stopTweens();
			// }

			previousTouch = e;
		};

		// new custom for double click + panning
		let pan = e => {
			if (e.drag.object !== null) {
				return;
			}
			
			let ndrag = {
				x: e.drag.lastDrag.x / this.renderer.domElement.clientWidth,
				y: e.drag.lastDrag.y / this.renderer.domElement.clientHeight
			};
			this.panDelta.x += ndrag.x;
			this.panDelta.y += ndrag.y;
			this.stopTweens();
		}

		this.addEventListener('touchstart', touchStart);
		this.addEventListener('touchend', touchEnd);
		this.addEventListener('touchmove', touchMove);
		this.addEventListener('drag', drag);
		this.addEventListener('drop', drop);
		this.addEventListener('pan', pan);
		this.addEventListener('mousewheel', scroll);
		this.addEventListener('dblclick', dblclick);
	}

	setScene (scene) {
		this.scene = scene;
	}

	stop(){
		this.yawDelta = 0;
		this.pitchDelta = 0;
		this.radiusDelta = 0;
		this.panDelta.set(0, 0);
	}

	set zoomingIn(val) {
		this.zoomIn(val)
	}

	set zoomingOut(val){
		this.zoomOut(val)
	}


	enableDragDropTimer (value) {
		return this.dragDropTimer = value;
	}
	
	zoomToLocation(mouse){
		let camera = this.scene.getActiveCamera();
		
		let I = Utils.getMousePointCloudIntersection(
			mouse,
			camera,
			this.viewer,
			this.scene.pointclouds,
			{pickClipped: true});

		if (I === null) {
			return;
		}

		let targetRadius = 0;
		{
			let minimumJumpDistance = 0.2;

			let domElement = this.renderer.domElement;
			let ray = Utils.mouseToRay(mouse, camera, domElement.clientWidth, domElement.clientHeight);

			let nodes = I.pointcloud.nodesOnRay(I.pointcloud.visibleNodes, ray);
			let lastNode = nodes[nodes.length - 1];
			let radius = lastNode.getBoundingSphere(new THREE.Sphere()).radius;
			targetRadius = Math.min(this.scene.view.radius, radius);
			targetRadius = Math.max(minimumJumpDistance, targetRadius);
		}

		let d = this.scene.view.direction.multiplyScalar(-1);
		let cameraTargetPosition = new THREE.Vector3().addVectors(I.location, d.multiplyScalar(targetRadius));
		// TODO Unused: let controlsTargetPosition = I.location;

		let animationDuration = 600;
		let easing = TWEEN.Easing.Quartic.Out;

		{ // animate
			let value = {x: 0};
			let tween = new TWEEN.Tween(value).to({x: 1}, animationDuration);
			tween.easing(easing);
			this.tweens.push(tween);

			let startPos = this.scene.view.position.clone();
			let targetPos = cameraTargetPosition.clone();
			let startRadius = this.scene.view.radius;
			let targetRadius = cameraTargetPosition.distanceTo(I.location);

			tween.onUpdate(() => {
				let t = value.x;
				this.scene.view.position.x = (1 - t) * startPos.x + t * targetPos.x;
				this.scene.view.position.y = (1 - t) * startPos.y + t * targetPos.y;
				this.scene.view.position.z = (1 - t) * startPos.z + t * targetPos.z;

				this.scene.view.radius = (1 - t) * startRadius + t * targetRadius;
				this.viewer.setMoveSpeed(this.scene.view.radius);
			});

			tween.onComplete(() => {
				this.tweens = this.tweens.filter(e => e !== tween);
			});

			tween.start();
		}
	}

	stopTweens () {
		this.tweens.forEach(e => e.stop());
		this.tweens = [];
	}

	update (delta) {
		let view = this.scene.view;

		{ // apply rotation
			let progression = Math.min(1, this.fadeFactor * delta);

			let yaw = view.yaw;
			let pitch = view.pitch;
			let pivot = view.getPivot();

			yaw -= progression * this.yawDelta;
			pitch -= progression * this.pitchDelta;

			view.yaw = yaw;
			view.pitch = pitch;

			let V = this.scene.view.direction.multiplyScalar(-view.radius);
			let position = new THREE.Vector3().addVectors(pivot, V);

			view.position.copy(position);
		}

		{ // apply pan
			let progression = Math.min(1, this.fadeFactor * delta);
			let panDistance = progression * view.radius * 3;

			let px = -this.panDelta.x * panDistance;
			let py = this.panDelta.y * panDistance;

			view.pan(px, py);
		}

		{ // apply zoom
			let progression = Math.min(1, this.fadeFactor * delta);

			// let radius = view.radius + progression * this.radiusDelta * view.radius * 0.1;
			let radius = view.radius + progression * this.radiusDelta;

			let V = view.direction.multiplyScalar(-radius);
			let position = new THREE.Vector3().addVectors(view.getPivot(), V);
			view.radius = radius;

			view.position.copy(position);
		}

		{
			let speed = view.radius;
			this.viewer.setMoveSpeed(speed);
		}

		{ // decelerate over time
			let progression = Math.min(1, this.fadeFactor * delta);
			let attenuation = Math.max(0, 1 - this.fadeFactor * delta);

			this.yawDelta *= attenuation;
			this.pitchDelta *= attenuation;
			this.panDelta.multiplyScalar(attenuation);
			// this.radiusDelta *= attenuation;
			this.radiusDelta -= progression * this.radiusDelta;
		}
	}
};
