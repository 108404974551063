import * as THREE from "../../libs/three.js/build/three.module.js";

import {Utils} from "../utils.js";


export class DrawSolarPanel extends THREE.Object3D {
	
	constructor(args = {}) {
		super();

		//console.log("Solar panel name: ", args.name)

		this.color = 0xffffff;
		this.constructor.counter = (this.constructor.counter === undefined) ? 1 : this.constructor.counter + 1;
		if(args.name && args.name.includes("_")) {
			this.name = args.name;
		} else {
			this.name = `${args.name}` + "_" + this.constructor.counter;
		}

		if(args.type) {
			this.type = args.type;
		}

		if(args.scene) {
			this.scene = args.scene;
		}

		this._selected = false;
		this._rotationPoint = undefined;		// Used when multiple panels are rotated together
		this.planeNormal = args.planeNormal || undefined;
		
		this.optimized = args.optimized;
		this.calculateBestFittingPlane = args.calculateBestFittingPlane;
		this.points = args.points;
		this.boundary = {};
		if(args.boundary) {
			this.boundary = args.boundary;
		}
		

		this.classification = "#2196f3"; // Is Classification NONE
		if(args.classification) {
			this.classification = args.classification;
		}

		this.title = args.title || this.name;

		if(args.id) {
			this.uuid = args.id;
		}

		this._orientation = args.orientation || args._orientation || "portrait";

		//console.log("Panel orientation: ", this._orientation);

		this._deleted = false;		// Flag to know if this class is in delete process for future events
		
		this.width = args.width || 1.134;
		this.height = args.height || 1.722;
		this.thickness = args.thickness || 0.03;

		this.spaceBetweenPanelsLeftRight = args.spaceBetweenPanelsLeftRight || 0.02;
		this.spaceBetweenPanelsTopBottom = args.spaceBetweenPanelsTopBottom || 0.02;
		this.offsetFromRoof = args.offsetFromRoof || 0.1;

		this.panelPower = args.panelPower || 400;

		let alpha = args.alpha || args.rotation?.x || 0;
		let beta = args.beta || args.rotation?.y || 0;
		let gamma = args.gamma || args.rotation?.z || 0;

		this.rotation.x = alpha;
		this.rotation.y = beta;
		this.rotation.z = gamma;

		// Needed for calculating plane when draging first time
		// to orient the this acordinally
		this.calculatePlaneIntervalTime = 100;
		this.lastCalculatePlaneTime = 0;
		
		{ // Needed for Potree.TransformationTool
			this.allowScaling = true;
			if(typeof args.allowScaling === "boolean") {
				this.allowScaling = args.allowScaling;
			}
			this.allowTranslating = true;
			if(typeof args.allowTranslating === "boolean") {
				this.allowTranslating = args.allowTranslating;
			}
			this.allowRotating = true;
			if(typeof args.allowRotating === "boolean") {
				this.allowRotating = args.allowRotating;
			}
			this.firstTimeDragged = false;
			if(typeof args.firstTimeDragged === "boolean") {
				this.firstTimeDragged = args.firstTimeDragged;
			}
			 
		}

		let boxGeometry = new THREE.BoxGeometry(1, 1, 1);
		boxGeometry.computeBoundingBox();

		this.loader = new THREE.TextureLoader();
		this.material = undefined;
		this.texture = undefined;
		if(args.texture) {
			this.texture = args.texture;
			let topTexture = this.loader.load(args.texture);
			this.material = [
				new THREE.MeshLambertMaterial( {color: 'black'}),
    	    	new THREE.MeshLambertMaterial( {color: 'black'}),
				new THREE.MeshLambertMaterial( {color: 'black'}),
				new THREE.MeshLambertMaterial( {color: 'black'}),
    	    	new THREE.MeshLambertMaterial({ map: topTexture}),
				new THREE.MeshLambertMaterial({ map: this.loader.load("skins/solar_panel_back.jpg")})
			];
		} else {
			this.material = new THREE.MeshLambertMaterial({
				color: 0xFF0000,
				transparent: false,
				side: THREE.DoubleSide,
				opacity: 0.5,
				depthTest: false,
				depthWrite: false,
			});
		}

		let boxFrameGeometry = new THREE.Geometry();
		{			
			// bottom
			boxFrameGeometry.vertices.push(new THREE.Vector3(-0.5, -0.5, 0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(0.5, -0.5, 0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(0.5, -0.5, 0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(0.5, -0.5, -0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(0.5, -0.5, -0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(-0.5, -0.5, -0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(-0.5, -0.5, -0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(-0.5, -0.5, 0.5));
			// top
			boxFrameGeometry.vertices.push(new THREE.Vector3(-0.5, 0.5, 0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(0.5, 0.5, 0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(0.5, 0.5, 0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(0.5, 0.5, -0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(0.5, 0.5, -0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(-0.5, 0.5, -0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(-0.5, 0.5, -0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(-0.5, 0.5, 0.5));
			// sides
			boxFrameGeometry.vertices.push(new THREE.Vector3(-0.5, -0.5, 0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(-0.5, 0.5, 0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(0.5, -0.5, 0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(0.5, 0.5, 0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(0.5, -0.5, -0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(0.5, 0.5, -0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(-0.5, -0.5, -0.5));
			boxFrameGeometry.vertices.push(new THREE.Vector3(-0.5, 0.5, -0.5));

			boxFrameGeometry.colors.push(new THREE.Vector3(1, 1, 1));
		}

		let planeFrameGeometry = new THREE.Geometry();
		{						
			// middle line
			planeFrameGeometry.vertices.push(new THREE.Vector3(-0.5, -0.5, 0.0));
			planeFrameGeometry.vertices.push(new THREE.Vector3(-0.5, 0.5, 0.0));
			planeFrameGeometry.vertices.push(new THREE.Vector3(0.5, 0.5, 0.0));
			planeFrameGeometry.vertices.push(new THREE.Vector3(0.5, -0.5, 0.0));
			planeFrameGeometry.vertices.push(new THREE.Vector3(-0.5, 0.5, 0.0));
			planeFrameGeometry.vertices.push(new THREE.Vector3(0.5, 0.5, 0.0));
			planeFrameGeometry.vertices.push(new THREE.Vector3(-0.5, -0.5, 0.0));
			planeFrameGeometry.vertices.push(new THREE.Vector3(0.5, -0.5, 0.0));
		}

		this.dimension = new THREE.Vector3(1, 1, 1);
		
		let initialPosition = args.position || {x: 0, y: 0, z: 0};

		this.position.set(initialPosition.x, initialPosition.y, initialPosition.z);

		// We can use these points to snap the panels right
		if(Array.isArray(this.points) && this.points.length > 0) {
			//console.log("Received points: ", this.points);
			//this.snapPanel(this.points);
		}

		this.box = new THREE.Mesh(boxGeometry, this.material);
		this.box.geometry.computeBoundingBox();
		this.boundingBox = this.box.geometry.boundingBox;
		this.add(this.box);
		


		this.frame = new THREE.LineSegments( boxFrameGeometry, new THREE.LineBasicMaterial({color: 0x000000}));
		this.add(this.frame);
		this.planeFrame = new THREE.LineSegments( planeFrameGeometry, new THREE.LineBasicMaterial({color: 0x000000}));
		this.add(this.planeFrame);

		// set default dimensions
		this.setScaleZ(this.thickness);	// Thickness
		this.setScaleY(this.height);	// Height
		this.setScaleX(this.width);		// Width

		// create local coordinate system
		let createArrow = (name, direction, color) => {
			let material = new THREE.MeshBasicMaterial({
				color: color, 
				depthTest: false, 
				depthWrite: false});
				
			let shaftGeometry = new THREE.Geometry();
			shaftGeometry.vertices.push(new THREE.Vector3(0, 0, 0));
			shaftGeometry.vertices.push(new THREE.Vector3(0, 1, 0));
			
			let shaftMaterial = new THREE.LineBasicMaterial({
				color: color, 
				depthTest: false, 
				depthWrite: false,
				transparent: true
				});
			let shaft = new THREE.Line(shaftGeometry, shaftMaterial);
			shaft.name = name + "_shaft";
			
			let headGeometry = new THREE.CylinderGeometry(0, 0.04, 0.1, 10, 1, false);
			let headMaterial = material;
			let head = new THREE.Mesh(headGeometry, headMaterial);
			head.name = name + "_head";
			head.position.y = 1;
			
			let arrow = new THREE.Object3D();
			arrow.name = name;
			arrow.add(shaft);
			arrow.add(head);

			return arrow;
		};

		this.arrowX = createArrow("arrow_x", new THREE.Vector3(1, 0, 0), 0xFF0000);
		this.arrowY = createArrow("arrow_y", new THREE.Vector3(0, 1, 0), 0x00FF00);
		this.arrowZ = createArrow("arrow_z", new THREE.Vector3(0, 0, 1), 0x0000FF);
		
		this.arrowX.rotation.z = -Math.PI / 2;
		this.arrowZ.rotation.x = Math.PI / 2;

		this.arrowX.visible = false;
		this.arrowY.visible = false;
		this.arrowZ.visible = false;

		this.add(this.arrowX);
		this.add(this.arrowY);
		this.add(this.arrowZ);

		{ // Event Listeners
			let drag = (e) => {
				if(!this.firstTimeDragged) {

					let I = Utils.getMousePointCloudIntersection(
						e.drag.end, 
						e.viewer.scene.getActiveCamera(), 
						e.viewer, 
						e.viewer.scene.pointclouds,
						{pickClipped: true});
	
					if (I) {
					
						//console.log("Mouse location: ", I.location)
						this.updatePosition(I.location);
						if(Array.isArray(this.boundary.points) && this.boundary.points.length > 0) {
							// In general a bit stable result
							this.snapPanel(this.boundary.points);
						} else if((new Date().getTime() - this.lastCalculatePlaneTime) > this.calculatePlaneIntervalTime) {
							this.lastCalculatePlaneTime = new Date().getTime();
							
							if(Array.isArray(this.points) && this.points.length > 0) {
								// Use the points defined by the boundary polygon in case of polygon points are not provided
								this.snapPanel(this.points);
							} else {
								// Use the point inside the bounding box of this 3d object
								let pointsInBox = I.pointcloud.root.getPointsInBoxFast(this.box).points;
								//Utils.debugBox(this, this.boundingBox, new THREE.Matrix4(), 0xff0000)
								if(Array.isArray(pointsInBox) && pointsInBox.length > 0) {

									let points = pointsInBox;

									//console.log('Points in box: ', points)

									this.snapPanel(points);
								}
							}
						}
					}
				}
			};

			let drop = e => {
				// Prevent on every single movement this event to be triggered
				// After the first drag and drop we rely on the select and deselect events. They are les noisy
				if(!this.firstTimeDragged) {
					this.dispatchEvent({
						'type': 'texturedSurface_dropped',
						'index': 0
					});

					this.firstTimeDragged = true;
				}
				
			};

			// For sub meshes/objects
			//let mouseover = (e) => e.object.material.emissive.setHex(0x2196f3);
			//let mouseleave = (e) => e.object.material.emissive.setHex(0x000000);

			// Class wise
			let mouseover = () => {
				document.body.style.cursor = 'pointer';
				if(!this._selected) {

					this.highLight();
				}
				
			}
			let mouseleave = () => {
				document.body.style.cursor = 'auto';
				if(!this._selected) {
					this.unHighLight();
				}
				
			}

			if(window.user.roles.edit){

				this.box.addEventListener('drag', drag);
				this.box.addEventListener('drop', drop);
				//this.box.addEventListener('mouseover', mouseover);
				//this.box.addEventListener('mouseleave', mouseleave);


				this.addEventListener('mouseover', mouseover);
				this.addEventListener('mouseleave', mouseleave);


				this.addEventListener("ui_select", e => {
					this.arrowX.visible = true;
					this.arrowY.visible = true;
					this.arrowZ.visible = true; 
				});
				this.addEventListener("ui_deselect", e => {
					this.arrowX.visible = false;
					this.arrowY.visible = false;
					this.arrowZ.visible = false; 				
				});

				// Activates the Potree.TransformationTool
				this.addEventListener("select", e => {
					//console.log("Selecting Panel: ", this.uuid);
					this._selected = true;
					this.highLight();
					let scene_header = $("#" + this.name + " .scene_header");
					if(!scene_header.next().is(":visible")) {
						scene_header.click();
					}
				});
				this.addEventListener("deselect", e => {
					//console.log("Deselecting Panel: ", this.uuid);
					this._selected = false;
					this.unHighLight();
					let scene_header = $("#" + this.name + " .scene_header");
					if(scene_header.next().is(":visible")) {
						scene_header.click();
					}
				});


				
			}

			this.onKeyDown = this.onKeyDown.bind(this)
			document.addEventListener("keydown", this.onKeyDown);

			this.onKeyUp = this.onKeyUp.bind(this);
			document.addEventListener("keyup", this.onKeyUp);

			
		}
			
	}

	onKeyDown(e) {
		let key = e.key;
		let ctrlKey = e.ctrlKey;
		let shiftKey = e.shiftKey;
		//console.log("This selected: ", this._selected);
		//console.log("Key: ", key)
		
		if(this._selected) {
			// we will move the panel in the xy-direction
			switch(key) {
				case "ArrowLeft": {
					if(!ctrlKey && !shiftKey) {
						//console.log("Moving Left: ", this);
						let axisVector = new THREE.Vector3(1, 0, 0);  // The X-axis is to move left or right
						let dir = -0.01;
						if(this._orientation === "landscape") {
							axisVector = new THREE.Vector3(0, 1, 0);  // The Y-axis is to move left or right
							dir = 0.01;
						}
							
						this.translateOnAxis(axisVector, dir );
						
						
					} else {
						if(!this._rotationPoint) {
							let axisVector = new THREE.Vector3(0, 0, 1);  // The Z-axis is to rotate left or right
							this.rotateOnAxis(axisVector, (Math.PI/180));
						} else {
							//console.log("Rotation Point: ", this._rotationPoint);
							this.rotateAroundPoint((Math.PI/180)*0.1)
						}
						
					}
					
					break;
				}
				case "ArrowRight": {
					if(!ctrlKey && !shiftKey) {
						//console.log("Moving Right: ", this);
						let axisVector = new THREE.Vector3(1, 0, 0);  // The X-axis is to move left or right
						let dir = 0.01;
						if(this._orientation === "landscape") {
							axisVector = new THREE.Vector3(0, 1, 0);  // The y-axis is to move left or right
							dir = -0.01;
						}

						this.translateOnAxis(axisVector, dir );
						
					} else {
						if(!this._rotationPoint) {
							let axisVector = new THREE.Vector3(0, 0, 1);  // The Z-axis is to rotate left or right
							this.rotateOnAxis(axisVector, -(Math.PI/180));
						} else {
							//console.log("Rotation Point: ", this._rotationPoint);
							this.rotateAroundPoint(-(Math.PI/180)*0.1);
						}
					}
					break;
				}
				case "ArrowDown": {
					if(!ctrlKey && !shiftKey) {
						//console.log("Moving Down: ", this);
						let axisVector = new THREE.Vector3(0, 1, 0);  // The Y-axis is to move up or down
						if(this._orientation === "landscape") {
							axisVector = new THREE.Vector3(1, 0, 0);  // The x-axis is to move up or down
						}
						this.translateOnAxis(axisVector, -0.01 );
					} else {
						if(!this._rotationPoint) {
							let axisVector = new THREE.Vector3(1, 0, 0);  // The X-axis is to rotate up or down
							//this.rotateOnAxis(axisVector, (Math.PI/180));
						} else {
							//console.log("Rotation Point: ", this._rotationPoint);
						}
					}
					
					break;
				}
				case "ArrowUp": {
					if(!ctrlKey && !shiftKey) {
						//console.log("Moving Up: ", this);
						let axisVector = new THREE.Vector3(0, 1, 0);  // The X-axis is to move up or down
						if(this._orientation === "landscape") {
							axisVector = new THREE.Vector3(1, 0, 0);  // The x-axis is to move up or down
						}
						this.translateOnAxis(axisVector, 0.01 );
					} else {
						if(!this._rotationPoint) {
							let axisVector = new THREE.Vector3(1, 0, 0);  // The X-axis is to rotate up or down
							//this.rotateOnAxis(axisVector, -(Math.PI/180));
						} else {
							//console.log("Rotation Point: ", this._rotationPoint);
						}
						
					}
					
					break;
				}
				case "Delete": {
					//console.log("Delete KEy: ", this);

					// Mark this as deleted
					this._deleted = true;

					// Pass it through so we can let the parent delete this.
					this.dispatchEvent({
						'type': 'texturedSurface_deleted',
						'index': 0
					});
				}
				default: {
	
					break;
				}
				
			}
		}
		
	}

	onKeyUp(e) {
		//console.log("On Key Up");
	}

	snapPanel(points) {
		if(!this.optimized) {
									
			// Extract three points from the array
			let p1 = points[0];
			let p2 = points[Math.round(points.length/2)];
			let p3 = points[points.length-1];
			
			// Create two vectors from the three points
			const v1 = new THREE.Vector3().subVectors(p2, p1);
			const v2 = new THREE.Vector3().subVectors(p3, p1);

			// Calculate the normal vector to the plane
			const normal = new THREE.Vector3().crossVectors(v1, v2).normalize();
			// get the normal of the plane the points lie in
			const plane = new THREE.Plane().setFromNormalAndCoplanarPoint(normal, p1.normalize());
			
			//Utils.debugPlane(this, plane, 1, 0xff0000);

			// get the side axis in world space
			let pt1 = this.localToWorld(new THREE.Vector3(0, 0, 1));
			let srcV = this.position.clone().sub(pt1);
			srcV.normalize();

			let tgtV = plane.normal.clone();
			tgtV.normalize();
			
			// calculate the transformation and apply it
			let qt = new THREE.Quaternion().setFromUnitVectors(srcV, tgtV);
			this.applyQuaternion(qt);
		
		} else {
			//console.log("Using optimized plane alligment");
			
			// Calculate the centroid of the points
			const centroid = new THREE.Vector3();
			points.forEach(point => centroid.add(point));
			centroid.divideScalar(points.length);

			let bestFittingPlane = this.calculateBestFittingPlane(points);
			//console.log("Plane equation: ", bestFittingPlane);

			/*
			if(this.scene) {
				for(let i=0; i<points.length; i++ ) {
					let point = points[i];
					Utils.debugSphere(this.scene, point, 0.05, 0xff0000);
				}
			}
			*/
			
			let planeNormal = new THREE.Vector3(bestFittingPlane.A, bestFittingPlane.B, bestFittingPlane.C);
			this.planeNormal = planeNormal;

			//console.log("Centroid: ", centroid);
			// The plane can be defined by its normal and the centroid

			/*
			if(this.scene) {
				let normal = new THREE.Vector3(bestFittingPlane.A, bestFittingPlane.B, bestFittingPlane.C);
				const plane = new THREE.Plane().setFromNormalAndCoplanarPoint(this.localToWorld(normal), centroid);

				console.log("Plane normal:", plane.normal);
				console.log("Plane constant:", plane.constant);

				Utils.debugPlane(this.scene, plane, 1, 0xff0000);
			}
			*/

			

			// Calculate the quaternion to rotate the object's up vector to the plane normal
			const upVector = new THREE.Vector3(0, 0, 1);  // Assuming Z is the up vector for the object
			const quaternionToAlignUp = new THREE.Quaternion().setFromUnitVectors(upVector, planeNormal);

			// Apply the rotation to the object
			this.setRotationFromQuaternion(quaternionToAlignUp);

			
			// Use the normal of the plane (named azimuth because it is like the azimuth) to align the y axis of the solar panel
			
			// Determine the current y-axis direction of the solar panel after the initial rotation
			const currentYAxis = new THREE.Vector3(0, 1, 0).applyQuaternion(this.quaternion);

			// calculate the angle difference between the y-axis of the panel and y-axis of the plane normal
			const azimuthRadians = Math.atan2(
				planeNormal.y, planeNormal.x
			) - Math.atan2(currentYAxis.y, currentYAxis.x);

			// Convert to degrees with +180 for changing direction. It is easier to normalize the value between [0, 360] degrees
			let azimuthDegrees = (azimuthRadians * (180 / Math.PI)) + 180; // flip it
			if(this._orientation === "landscape") {
				azimuthDegrees = azimuthDegrees + 90;
			}

			// Normalize to [0, 360] degrees
			if (azimuthDegrees < 0) {
				azimuthDegrees += 360;
			}

			// Rotate the solar panel around its z-axis (normal vector of the solar panel) to align the y-axis with the slope direction
			this.rotateOnAxis(upVector, (azimuthDegrees*(Math.PI/180)));
			

			// Now translate on the z-axis to give it an offset
			this.translateOnAxis(upVector, (this.thickness/2)+this.offsetFromRoof );
		}
	}
	

	highLight() {

		this.arrowX.visible = true;
		this.arrowY.visible = true;
		this.arrowZ.visible = true;

		if(Array.isArray(this.material) && this.material.length > 0) {
			for(let i=0; i < this.material.length; i++){
				this.material[i].emissive.setHex(0x2196f3);
				this.material[i].opacity = 0.5;
				this.material[i].transparent = true;
			}
		} else {
			this.material.emissive.setHex(0x2196f3);
			this.material = opacity = 0.5;
			this.material.transparent = true;
		}
		
	}

	unHighLight() {

		//console.log("Unhighlighting panel: ", this)
		this.arrowX.visible = false;
		this.arrowY.visible = false;
		this.arrowZ.visible = false;

		if(Array.isArray(this.material) && this.material.length > 0) {
			for(let i=0; i < this.material.length; i++){
				this.material[i].emissive.setHex(0x000000);
				this.material[i].opacity = 1.0;
				this.material[i].transparent = false;
			}
		} else {
			this.material.emissive.setHex(0x000000);
			this.material = opacity = 1.0;
			this.material.transparent = true;
		}
	}

	setScaleX(x) {
		this.box.scale.x = x;
		this.frame.scale.x = x;
		this.planeFrame.scale.x = x;			
	}

	setScaleY(y) {
		this.box.scale.y = y;
		this.frame.scale.y = y;
		this.planeFrame.scale.y = y;		
	}

	setScaleZ(z) {
		this.box.scale.z = z;
		this.frame.scale.z = z;
		this.planeFrame.scale.z = z;		
	}

	/**
	 * Function to rotate around the x, y or z axis
	 * @param {*} args 
	 * @returns 
	 */
	rotate(args) {
		let cs = args.cs || null;
		let axis = args.axis || null;
		let dir = args.dir || null;

		if(!cs || !axis || !dir) return;

		if(cs === "local") {
			if(axis === "x") {
				this.rotateOnAxis(new THREE.Vector3(1, 0, 0), dir * this.clipRotOffset * Math.PI / 180);
			} else if(axis === "y") {
				this.rotateOnAxis(new THREE.Vector3(0, 1, 0), dir * this.clipRotOffset * Math.PI / 180);
			} else if(axis === "z") {
				this.rotateOnAxis(new THREE.Vector3(0, 0, 1), dir * this.clipRotOffset * Math.PI / 180);
			}
		} else if(cs === "global") {
			let rotaxis = new THREE.Vector4(1, 0, 0, 0);	
			if(axis === "y") {
				rotaxis = new THREE.Vector4(0, 1, 0, 0);
			} else if(axis === "z") {
				rotaxis = new THREE.Vector4(0, 0, 1, 0);
			}
			this.updateMatrixWorld();
			let invM = new THREE.Matrix4().getInverse(this.matrixWorld);
			rotaxis = rotaxis.applyMatrix4(invM).normalize();
			rotaxis = new THREE.Vector3(rotaxis.x, rotaxis.y, rotaxis.z);
			this.rotateOnAxis(rotaxis, dir * this.clipRotOffset * Math.PI / 180);
		}

		this.updateLocalSystem();

		this.dispatchEvent({"type": "texturedSurface_changed", "viewer": viewer, "volume": this});
	}

	/**
	 * Rotate around a point instead of x,y,z axis
	 * @param {*} alpha 
	 */
	rotateAroundPoint(alpha) {
		if(this._rotationPoint.x && this._rotationPoint.y && this.planeNormal) {
			let rotationAxis = new THREE.Vector3(this.planeNormal.x, this.planeNormal.y, this.planeNormal.z);
			let centerPositionPanel = this.position;
			let rotationPoint = new THREE.Vector3(this._rotationPoint.x, this._rotationPoint.y, this._rotationPoint.z);

			let diffVector = new THREE.Vector3().subVectors(rotationPoint, centerPositionPanel);

			//console.log("Diff vector: ", diffVector);

			let q = new THREE.Quaternion().setFromAxisAngle(rotationAxis, alpha);	
			let newRotationPoint = diffVector.applyQuaternion(q);

			//console.log("New rotation point: ", newRotationPoint);

			let diffInTranslation = new THREE.Vector3().subVectors(rotationPoint, newRotationPoint);
			//console.log("Diff in translation: ", diffInTranslation);

			this.rotateOnAxis(new THREE.Vector3(0, 0, 1), alpha);

			
			this.position.set(diffInTranslation.x, diffInTranslation.y, diffInTranslation.z);
		}
		
	}


	updatePosition (position) {
		this.position.copy(position);
	}

	update () {
		
		this.box.geometry.computeBoundingBox();
		this.boundingBox = this.box.geometry.boundingBox;
		this.box.visible = true;

		this.updateLocalSystem();
	};

	updateLocalSystem() {		
		// extract local coordinate axes
		let rotQuat = this.getWorldQuaternion();
		this.localX = new THREE.Vector3(1, 0, 0).applyQuaternion(rotQuat).normalize();
		this.localY = new THREE.Vector3(0, 1, 0).applyQuaternion(rotQuat).normalize();
		this.localZ = new THREE.Vector3(0, 0, 1).applyQuaternion(rotQuat).normalize();
	}

	raycast (raycaster, intersects) {
		let is = [];
		this.box.raycast(raycaster, is);

		if (is.length > 0) {
			let I = is[0];
			intersects.push({
				distance: I.distance,
				object: this,
				point: I.point.clone()
			});
		}
	};

	clear() {
		//console.log(`Clearing ${this.uuid}`);
		document.removeEventListener("keydown", this.onKeyDown);
		document.removeEventListener("keyup", this.onKeyUp);

		if(Array.isArray(this.material) && this.material.length > 0) {
			for(let i=0; i < this.material.length; i++){
				this.material[i].dispose();

			}
		} else {
			this.material.dispose();
		}
	}

	set selected (value) {
		this._selected = value;
	}
	get selected () {
		return this._selected;
	}
	
	set deleted (value) {
		this._deleted = value;
	}
	get deleted () {
		return this._deleted;
	}
	
	set rotationPoint (value) {
		this._rotationPoint = value;
	}
	get rotationPoint () {
		return this._rotationPoint;
	}

	set orientation (value) {
		this._orientation = value;
	}
	get orientation (){
		return this._orientation;
	}
}
